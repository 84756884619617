import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import ChecklistExecutable from 'src/components/checklist/checklist/ChecklistExecutable'
import { checkListStatusColorMapper } from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import { checklistQueryKey, useChecklist } from 'src/query/checklist'
import { updateCheckList } from 'src/service/ChecklistService'
import { IChecklist } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'

const ChecklistDetailPage = ({
  checklistId,
  writeAccess = false,
}: {
  checklistId: number
  writeAccess?: boolean
}) => {
  const { data } = useChecklist(checklistId)
  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: [checklistQueryKey] })
  }
  const { t } = useTranslation()
  const projectId = useProjectId()

  const onUpdate = async (update: Partial<IChecklist>) => {
    await updateCheckList(projectId, checklistId, {
      ...update,
      id: checklistId,
    })
    reload()
  }

  return (
    <div className={'py-2'}>
      <div
        className={
          'flex text-lg font-bold justify-items-between items-center max-w-[800px]'
        }
      >
        <TextInlineInputCompontent
          className={'flex'}
          disabled={!writeAccess}
          value={data?.name}
          onValueSubmitted={(newValue) => onUpdate({ name: newValue })}
        />
        {data?.status && (
          <Badge
            className={'h-6 min-w-36'}
            text={t(data.status)}
            color={checkListStatusColorMapper[data.status]}
          />
        )}
      </div>
      <ChecklistExecutable checklist={data!} />
    </div>
  )
}

export default ChecklistDetailPage
