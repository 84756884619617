export const checklistImportTemplate = {
  name: 'checklist_import_template',
  displayName: 'checklist_import_template',
  columns: ['chapter', 'item', 'data_type', 'options'],
  template: [
    {
      name: 'chapter',
      required: false,
      value: '',
      description: 'checklist_chapter_import_description',
      example: 'Chapter 01',
    },
    {
      name: 'item',
      required: true,
      value: '',
      description: 'checklist_item_import_description',
      example: 'Item 01',
    },
    {
      name: 'data_type',
      required: true,
      value: 'checklist_data_type_options',
      description: 'checklist_data_type_import_description',
      example: 'text',
    },
    {
      name: 'options',
      required: false,
      value: 'list_of_options_separated_by_comma',
      description: 'checklist_pattern_import_description',
      example: 'Option 1, Option 2',
    },
  ],
  example: [
    {
      chapter: 'RØRLEDNINGSKONTROLL',
      item: 'Utmålinger',
      data_type: 'text',
      options: '',
    },
    {
      chapter: '',
      item: 'Rydding gjennomført etter egne arbeider på dato',
      data_type: 'date',
      options: '',
    },
    {
      chapter: '',
      item: 'Koordinering andre entreprenører',
      data_type: 'checkbox',
      options: '',
    },
    {
      chapter: 'SPRINKLER',
      item: 'Typer sprinklerhoder benyttet i hht. beskrivelse',
      data_type: 'enum',
      options: 'Oransje, Rød, Gul, Grønn, Blå, Lilla',
    },
    {
      chapter: 'RUH',
      item: 'Hvilke personlig verneutstyr var ikke i bruk',
      data_type: 'multi_enum',
      options: 'Hjelm, Vernesko, Synlighetstøy, Briller',
    },
  ],
}
