import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { ErrorOption, FieldPath } from 'react-hook-form'
import useProjectId from 'src/components/hooks/useProjectId'
import useTree from 'src/hooks/useTree'
import {
  createCheckListTemplate,
  deleteChecklistTemplate,
  deleteMultipleChecklistTemplates,
  filteredChecklistTemplates,
  getCheckListTemplate,
  getChecklistTemplatesTree,
} from 'src/service/ChecklistService'
import { IChecklistTemplate } from 'src/service/OrgTypes'

export const checklistTemplateQueryKey = 'ChecklistTemplate'

export const useChecklistTemplate = (checklistTemplateId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [checklistTemplateQueryKey, checklistTemplateId, projectId],
    queryFn: () => getCheckListTemplate(projectId, checklistTemplateId),
  })
}

export const useFilteredChecklistTemplates = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof filteredChecklistTemplates>[1]['sort']
  filters: Omit<Parameters<typeof filteredChecklistTemplates>[1], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'filteredChecklistTemplates',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      filteredChecklistTemplates(
        projectId,
        { ...filters, sort },
        page,
        pageSize,
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const useMutateChecklistTemplate = ({
  setError,
  onSuccess,
}: {
  setError?: (
    name: FieldPath<Partial<IChecklistTemplate>>,
    error: ErrorOption,
  ) => void
  onSuccess?: () => void
}) => {
  const projectId = useProjectId()
  const { reloadTree } = useTree(
    ['checklist_templates'],
    getChecklistTemplatesTree,
    'ChecklistTemplate',
  )
  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['filteredChecklistTemplates'] })
    reloadTree()
  }
  const createList = useMutation({
    mutationFn: (checklist: Partial<IChecklistTemplate>) =>
      createCheckListTemplate(projectId, checklist),
    onError: (
      error: Promise<{
        error_code: string
        errors: { attribute: string; type: string }[]
      }>,
    ) => {
      error.then((error) => {
        if (
          error.errors.some((e) => e.attribute === 'name' && e.type === 'taken')
        ) {
          setError?.('name', { message: 'name_is_already_in_use' })
        }
      })
    },
    onSuccess: () => {
      reload()
      onSuccess?.()
    },
  })
  const deleteList = useMutation({
    mutationFn: (id: number) => deleteChecklistTemplate(projectId, id),
    onSuccess: () => {
      reload()
    },
  })

  const bulkDeleteLists = useMutation({
    mutationFn: (ids: number[]) =>
      deleteMultipleChecklistTemplates(projectId, ids),
    onSuccess: () => {
      reload()
    },
  })

  return { createList, deleteList, bulkDeleteLists }
}
