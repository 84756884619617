import OpenInNew from '@icons/open_in_new.svg'
import { useQueryClient } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  checkListDetailUrl,
  checklistStatus,
  checkListStatusColorMapper,
} from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import { updateCheckList } from 'src/service/ChecklistService'
import { IChecklist } from 'src/service/OrgTypes'
import {
  defaultColumn,
  defaultDateColumnObject,
  defaultEditableTextColumn,
  defaultUserColumn,
  statusColumn,
  styleClass,
} from 'src/ui-elements/Table/Columns'
import { filterType } from 'src/ui-elements/list/ListTypes'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

export const useChecklistColumns = ({
  disabled = true,
  showTemplate = true,
}: {
  disabled: boolean
  showTemplate?: boolean
}) => {
  const queryClient = useQueryClient()

  const projectId = useProjectId()
  const { getUserFilter } = useListHelper()

  const columnHelper = createColumnHelper<IChecklist>()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['filteredChecklists'] })
  }

  const { t } = useTranslation()

  const ct = (s: string) => {
    return capitalize(t(s))
  }

  const update = async (id: number, value: Partial<IChecklist>) => {
    await updateCheckList(projectId, id, value)
    reload()
  }

  const columns = [
    columnHelper.accessor(
      'name',
      defaultEditableTextColumn(
        'name',
        { name: ct('name') },
        (id, value) => {
          update(+id, { name: value })
        },
        disabled,
      ),
    ),
    columnHelper.accessor(
      'description',
      defaultEditableTextColumn(
        'description',
        { name: ct('description') },
        (id, value) => {
          update(+id, { description: value })
        },
        disabled,
      ),
    ),
    statusColumn(
      'status',
      ['status'],
      { name: capitalize(t('status')) },
      checklistStatus(t),
      undefined,
      (cell) => {
        return checkListStatusColorMapper[cell.status]
      },
    ),
    columnHelper.accessor(
      'updated_at',
      defaultDateColumnObject('updated_at', { name: ct('updated_at') }),
    ),
    columnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: ct('created_at') }),
    ),
    columnHelper.accessor(
      'updated_by',
      defaultUserColumn('updated_by', {
        name: ct('updated_by'),
        field: 'updated_by.firstName||updated_by.lastName',
        getFilter: getUserFilter,
      }),
    ),
    columnHelper.accessor(
      'created_by',
      defaultUserColumn('created_by', {
        name: ct('created_by'),
        field: 'created_by.firstName||created_by.lastName',
        getFilter: getUserFilter,
      }),
    ),
    ...(!showTemplate
      ? [
          columnHelper.accessor('parent_type', {
            ...defaultColumn(
              'parent_type',
              {
                name: t('parent'),
              },
              filterType.TEXT,
            ),
            size: 200,
            cell: ({ row }) => {
              const url = checkListDetailUrl(
                row.original.id,
                row.original.parent_type,
                row.original.parent_id,
              )
              return (
                <span className={styleClass.cell}>
                  {url ? (
                    <Link
                      target="_blank"
                      className={'text-task-blue underline'}
                      to={url}
                    >
                      <div className={'flex items-center'}>
                        {`${t('go_to')} ${t(row.original.parent_type ?? '')}`}
                        <OpenInNew className={'ml-1 fill-task-blue block'} />
                      </div>
                    </Link>
                  ) : (
                    <span>{t('no_parent')}</span>
                  )}
                </span>
              )
            },
          }),
        ]
      : [
          columnHelper.accessor('checklist_template', {
            ...defaultColumn(
              'checklist_template',
              {
                name: t('checklist_template'),
              },
              filterType.TEXT,
            ),
            size: 200,
            cell: (props) => {
              return (
                <span className={styleClass.cell}>
                  {props.getValue()?.name && (
                    <Link
                      target="_blank"
                      className={'text-task-blue underline'}
                      to={getDetailUrl.checklistTemplate({
                        checklistTemplateId: props.getValue()?.id,
                        domain: props.getValue()?.domain,
                      })}
                    >
                      <div className={'flex items-center'}>
                        {props.getValue()?.name}
                        <OpenInNew className={'ml-1 fill-task-blue block'} />
                      </div>
                    </Link>
                  )}
                </span>
              )
            },
          }),
        ]),
  ] as ColumnDef<IChecklist>[]

  const defaultOrdering = [
    'select',
    'name',
    'checklist_template',
    'parent_type',
    'status',
    'description',
    'updated_at',
    'updated_by',
    'created_at',
    'created_by',
  ]

  return { columns, defaultOrdering }
}
