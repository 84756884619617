import DataSelectors from 'src/document/components/FileUpload/selectors/DataSelectors'
import { IFormatData } from 'src/document/components/FileUpload/types'

export interface ImportTemplate {
  name: string
  displayName: string
  columns: string[]
  template?: Record<string, string | boolean>[]
  example?: Record<string, string>[]
}

export const validateRowForImport = (
  key: IFormatData,
  header: string,
  value: string,
  da: IFormatData[] = [],
) => {
  const index = da.indexOf(key) ?? -1
  if (index === -1) return
  return da.map((fd: any, i: number) => {
    const data = {}
    Object.keys(fd).forEach((d: string) => {
      const newValue = i === index && d === header
      const headerMap = DataSelectors.getMapperFromHeader(d) ?? d
      const _v = fd[d]?.value
      data[headerMap] = newValue
        ? value
        : typeof _v === 'object' && _v
          ? _v['id']
          : _v
    })
    if (fd['user_defined_values']) {
      data['optional_fields'] = fd['user_defined_values']
      delete data['user_defined_values']
    }
    return data
  })
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const cleanUpHiddenChar = (item: string) => {
  return item.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

const translateBoolean = (value: boolean, t: any) => {
  return value ? capitalize(t('yes')) : capitalize(t('no'))
}

const translateTemplateInformation = (
  example: Record<string, string | boolean>[],
  t: any,
) => {
  return example.map((entry) => {
    const transformed: Record<string, string> = {}

    for (const [key, value] of Object.entries(entry)) {
      const newKey = capitalize(t(key))
      transformed[newKey] =
        typeof value === 'boolean'
          ? translateBoolean(value, t)
          : capitalize(t(value))
    }

    return transformed
  })
}

export const translateTemplate = (
  template: ImportTemplate,
  t: any,
): ImportTemplate => {
  const newTemplate: ImportTemplate = { ...template }
  newTemplate.displayName = t(newTemplate.displayName)
  newTemplate.columns = newTemplate.columns
    .map(t)
    .map(capitalize)
    .map(cleanUpHiddenChar)
  if (newTemplate.template) {
    newTemplate.template = translateTemplateInformation(newTemplate.template, t)
  }
  if (newTemplate.example)
    newTemplate.example = translateTemplateInformation(newTemplate.example, t)
  return newTemplate
}
