import Add from '@icons/add.svg'
import Close from '@icons/close.svg'
import { capitalize } from 'lodash'
import { FC, PropsWithChildren } from 'react'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface IInputProps {
  label?: string
  selectedItem?: { id: number; value: string }
  resetValue?: () => void
  placeholder?: string
  showModal: boolean
  setShowModal: (show: boolean) => void
  tooltipText?: string
}

const SelectorWithSelectModal: FC<PropsWithChildren<IInputProps>> = ({
  label,
  selectedItem,
  resetValue,
  placeholder,
  children,
  showModal,
  setShowModal,
  tooltipText,
}) => {
  return (
    <div className={'m-2'}>
      <p className={'font-medium text-sm leading-5 text-gray-700 my-2'}>
        {capitalize(label)}
      </p>
      <div className={'flex flex-row'}>
        <div
          className={
            'rounded-sm flex items-center justify-between text-sm bg-bg-grey w-full min-h-4 px-2 border border-gray-300 text-gray-800'
          }
        >
          {selectedItem && selectedItem?.value}
          {!selectedItem && placeholder && (
            <p className={'text-gray-400'}>{placeholder}</p>
          )}
          {resetValue && selectedItem && (
            <Button
              className={'p-0 bg-inherit border-none'}
              onClick={resetValue}
            >
              <Close className={'text-sm'} />
            </Button>
          )}
        </div>
        <Button
          toolTipText={tooltipText}
          className={'min-h-4 min-w-4 m-0 ml-2'}
          type={ButtonType.SECONDARY}
          onClick={() => setShowModal(true)}
        >
          <Add className={'text-xl'} />
        </Button>
      </div>
      {showModal && children}
    </div>
  )
}

export default SelectorWithSelectModal
