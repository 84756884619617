export const testCaseImportTemplate = {
  name: 'test_cases_import_template',
  displayName: 'test_cases_import_template',
  columns: ['chapter', 'item', 'expected_result'],
  template: [
    {
      name: 'chapter',
      required: false,
      description: 'name_of_test_chapter_test_element_belongs_to',
      example: '01.00 Utførelse',
    },
    {
      name: 'item',
      required: true,
      description: 'description_of_test_element',
      example: 'Montering av utstyr',
    },
    {
      name: 'expected_result',
      required: false,
      description: 'expected_result_of_test_element',
      example: 'Alt ser fint ut',
    },
  ],
  example: [
    {
      chapter: '01.00 Dokumentasjon',
      item: 'Tavledokumentasjon',
      expected_result:
        'Skal medfølge og inneholde arrangementstegning, koblingsskjema og komponentliste',
    },
    {
      chapter: '',
      item: 'Arrangementstegning',
      expected_result:
        'Gjenspeiler utstyret som er montert i tavlen og plasseringen av det',
    },
    {
      chapter: '02.00 Utførelse',
      item: 'Montering av utstyr',
      expected_result: 'Sitter godt fast',
    },
    {
      chapter: '',
      item: 'Ledere skrudd fast',
      expected_result: 'Godt terminert i utstyr og klemmer',
    },
  ],
}
