import { createStore } from 'zustand'
import { ICellUpdate } from '../types/ICellUpdate'
import { IExpandableSection } from '../types/IExpandableSection'
import { IExpandableSectionConfig } from '../types/IExpandableSectionConfig'
import { IHeader } from '../types/IHeader'
import { IRow } from '../types/IRow'

export interface IExpandableTableStore {
  headers: IHeader[]
  rows: Record<string, IRow>
  readonly: boolean
  expandableSections: IExpandableSection[]
  addSectionTitle: string
  sectionTitle: string
  itemTitle: string
  expandableSectionsConfig: Record<string, IExpandableSectionConfig>
  updateSectionsConfig: (
    sectionId: number,
    newConfig: Partial<IExpandableSectionConfig>,
  ) => void
  updateSectionTitle: (sectionId: number, newTitle: string) => void
  updateCell: (update: ICellUpdate) => void
  createRow: (
    sectionId: IExpandableSection['id'],
    initialRow: Record<string, any>,
    rowIndex?: number,
  ) => Promise<void>
  createSection: (sectionTitle: string) => Promise<void>
  collapse: () => Promise<void>
  expand: () => Promise<void>
  deleteRow: (rowId: string | number, sectionId: number) => void
  deleteSection: (sectionId: number) => void
  onReorderRows?: (
    row: IRow,
    oldIndex: number,
    newIndex: number,
    oldSectionId: IExpandableSection['id'],
    newSectionId: IExpandableSection['id'],
  ) => void
  onReorderColumns?: (
    header: IHeader,
    oldIndex: number,
    newIndex: number,
  ) => void
  randomNumber: boolean
  selectedIds: number[]
  selectedSectionIds: number[]
  bulkDelete: boolean
  updateSelectedIds: (ids: number[]) => void
  updateSelectedSectionIds: (ids: number[]) => void
  getSelectedIds: () => number[]
  isRowSelectable: boolean
  recentlyAddedRow?: { sectionId: number; rowId?: number }
  resetSelectedState: (
    deletedRowIds?: number[],
    deletedSectionIds?: number[],
  ) => void
  resetRecentlyAddedRow: () => void
}

export const createExpandableStore = (
  options: {
    onCellUpdated: (update: ICellUpdate) => void
    onSectionTitleUpdate?: (
      sectionId: IExpandableSection['id'],
      newTitle: string,
    ) => void
    onReorderRows?: (
      row: IRow,
      oldIndex: number,
      newIndex: number,
      oldSectionId: IExpandableSection['id'],
      newSectionId: IExpandableSection['id'],
    ) => void
    onReorderColumns?: (
      header: IHeader,
      oldIndex: number,
      newIndex: number,
    ) => void
    createRow?: (
      sectionId: IExpandableSection['id'],
      initialRow: Record<string, any>,
      rowIndex?: number,
    ) => Promise<IRow['id']>
    createSection?: (title: string) => Promise<IExpandableSection['id']>
    deleteRow?: (rowId: string | number) => void
    deleteSection?: (sectionId: number) => void
    onSelectCallback?: (
      selectedRowIds: number[],
      selectedSectionIds: number[],
    ) => void
    isRowSelectable?: boolean
  },
  initialStore?: Partial<IExpandableTableStore>,
) => {
  const {
    onCellUpdated,
    createRow,
    deleteRow,
    createSection,
    deleteSection,
    onReorderRows,
    onReorderColumns,
    onSectionTitleUpdate,
    isRowSelectable,
    onSelectCallback,
  } = options

  return createStore<IExpandableTableStore>((set, get) => ({
    headers: initialStore?.headers ?? [],
    randomNumber: initialStore?.randomNumber ?? false,
    expandableSections: initialStore?.expandableSections ?? [],
    rows: initialStore?.rows ?? {},
    expandableSectionsConfig: initialStore?.expandableSectionsConfig ?? {},
    readonly: initialStore?.readonly ?? false,
    isRowSelectable: !!isRowSelectable,
    itemTitle: initialStore?.itemTitle ?? '',
    sectionTitle: initialStore?.sectionTitle ?? '',
    addSectionTitle: initialStore?.addSectionTitle ?? '',
    selectedIds: initialStore?.selectedIds ?? [],
    selectedSectionIds: initialStore?.selectedSectionIds ?? [],
    bulkDelete: initialStore?.bulkDelete ?? false,
    updateSectionsConfig: (
      sectionId: number,
      newConfig: Partial<IExpandableSectionConfig>,
    ) => {
      const allConfigs = get().expandableSectionsConfig
      allConfigs[sectionId] = { ...allConfigs[sectionId], ...newConfig }
      set({ expandableSectionsConfig: allConfigs })
    },
    updateSectionTitle: (sectionId, newTitle) => {
      const tmpConfig = { ...get().expandableSectionsConfig[sectionId] }
      tmpConfig.title = newTitle
      if (onSectionTitleUpdate) onSectionTitleUpdate(sectionId, newTitle)
      set({
        expandableSectionsConfig: {
          ...get().expandableSectionsConfig,
          [sectionId]: tmpConfig,
        },
      })
    },
    updateCell: ({ newValue, header, rowId }) => {
      const tmpRows = { ...get().rows }
      tmpRows[rowId] = { ...tmpRows[rowId], [header.field]: newValue }
      onCellUpdated({ newValue, header, rowId })
      set({ rows: tmpRows })
    },
    createRow: async (sectionId, initialCell, rowIndex = -1) => {
      if (!createRow) return
      const id = await createRow(sectionId, initialCell, rowIndex)
      const tmpRows = { ...get().rows }

      tmpRows[id] = { ...initialCell, id }

      set({ rows: tmpRows, recentlyAddedRow: { sectionId, rowId: id } })

      const tmpSectionConfig = { ...get().expandableSectionsConfig[sectionId] }
      if (rowIndex >= 0) {
        tmpSectionConfig.rows.splice(rowIndex + 1, 0, id)
      } else {
        tmpSectionConfig.rows.push(id)
      }

      get().updateSectionsConfig(sectionId, tmpSectionConfig)
    },
    deleteRow: async (rowId: number, sectionId: number) => {
      if (!deleteRow) return
      await deleteRow(rowId)
      const tmpRows = { ...get().rows }
      delete tmpRows[rowId]

      set({ rows: tmpRows })
      get().resetSelectedState([rowId])

      const tmpSectionConfig = { ...get().expandableSectionsConfig[sectionId] }

      tmpSectionConfig.rows.splice(tmpSectionConfig.rows.indexOf(rowId), 1)
      get().updateSectionsConfig(sectionId, tmpSectionConfig)
    },
    createSection: async (title: string) => {
      if (!createSection) return
      const id = await createSection(title)
      const tmpSections = [...get().expandableSections]
      tmpSections.push({ id })

      const tmpSectionsConfig = { ...get().expandableSectionsConfig }
      tmpSectionsConfig[id] = {
        id,
        isExpanded: true,
        rows: [],
        title,
      }
      set({
        expandableSections: tmpSections,
        expandableSectionsConfig: tmpSectionsConfig,
        recentlyAddedRow: { sectionId: id },
      })
    },
    collapse: async () => {
      const tmpSectionsConfig = { ...get().expandableSectionsConfig }
      Object.keys(tmpSectionsConfig).map((s) => {
        tmpSectionsConfig[s].isExpanded = false
      })
      set({
        expandableSectionsConfig: tmpSectionsConfig,
        expandableSections: get().expandableSections,
      })
    },
    expand: async () => {
      const tmpSectionsConfig = { ...get().expandableSectionsConfig }
      Object.keys(tmpSectionsConfig).map((s) => {
        tmpSectionsConfig[s].isExpanded = true
      })
      set({
        expandableSectionsConfig: tmpSectionsConfig,
      })
    },
    deleteSection: async (sectionId: number) => {
      if (!deleteSection) return
      const tmpSectionsConfig = { ...get().expandableSectionsConfig }

      await deleteSection(sectionId)

      const deleteRows = tmpSectionsConfig[sectionId].rows

      delete tmpSectionsConfig[sectionId]

      const filteredSections = [...get().expandableSections].filter(
        (ts) => ts.id !== sectionId,
      )

      const tmpRows = { ...get().rows }

      deleteRows.map((r) => {
        delete tmpRows[r]
      })

      get().resetSelectedState(deleteRows, [sectionId])
      set({
        expandableSectionsConfig: tmpSectionsConfig,
        expandableSections: filteredSections,
        rows: tmpRows,
      })
    },
    onReorderColumns,
    onReorderRows,
    updateSelectedIds: (newValue: number[]) => {
      set({ selectedIds: newValue })
      onSelectCallback?.(newValue, get().selectedSectionIds)
    },
    updateSelectedSectionIds: (newValue: number[]) => {
      set({ selectedSectionIds: newValue })
      onSelectCallback?.(get().selectedIds, newValue)
    },
    getSelectedIds: () => {
      return get().selectedIds
    },
    resetSelectedState: (deletedRowIds, deletedSectionIds) => {
      const tmpSelectedRows = get().selectedIds.filter(
        (id) => !deletedRowIds?.includes(id),
      )
      const tmpSelectedSections = get().selectedSectionIds.filter(
        (id) => !deletedSectionIds?.includes(id),
      )
      set({
        selectedIds: tmpSelectedRows,
        selectedSectionIds: tmpSelectedSections,
      })
    },
    resetRecentlyAddedRow: () => set({ recentlyAddedRow: undefined }),
  }))
}
