import { useChecklistTemplateColumns } from 'src/components/checklist/checklist-template/useChecklistTemplateColumns'
import { useFilteredChecklistTemplates } from 'src/query/checklistTemplate'
import { IChecklistTemplate } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

const ChecklistTemplateSelectList = ({
  onSelect,
}: {
  onSelect: (checklistTemplate: IChecklistTemplate) => void
}) => {
  const { columns, defaultOrdering } = useChecklistTemplateColumns(false)

  const generateRedirectUrl = (row: IChecklistTemplate) => {
    return getDetailUrl.checklistTemplate({
      checklistTemplateId: row.id,
      domain: row.domain,
    })
  }

  return (
    <>
      <div className={'w-full flex px-2 pb-4 flex-col min-h-[48vh]'}>
        <Table
          name={'selectChecklistTemplates'}
          generateRedirectUrl={generateRedirectUrl}
          columns={columns}
          onRowClick={onSelect}
          selectable={false}
          defaultOrdering={defaultOrdering}
          useDataQuery={useFilteredChecklistTemplates}
          modelName={DataModel.CHECKLIST_TEMPLATE}
        />
      </div>
    </>
  )
}

export default ChecklistTemplateSelectList
