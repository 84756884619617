import { useQueryClient } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChecklistActionButtons from 'src/components/checklist/checklist/ChecklistActionButtons'
import ChecklistValueCell from 'src/components/checklist/checklist/ChecklistValueCell'
import { ChecklistStatus } from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import { BASE_METADATA_TYPES } from 'src/document/types/FolderMetadataTypes'

import { checklistQueryKey } from 'src/query/checklist'
import { updateCheckListItem } from 'src/service/ChecklistService'
import {
  IChecklist,
  IChecklistGroup,
  IChecklistItem,
} from 'src/service/OrgTypes'
import ExpandableTable from 'src/systematic-completion/components/expandable-table/ExpandableTable'
import { useExpandableTable } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTable'
import { COLUMN_TYPES } from 'src/systematic-completion/components/expandable-table/types/COLUMN_TYPES'
import { ICellUpdate } from 'src/systematic-completion/components/expandable-table/types/ICellUpdate'
import { IExpandableSection } from 'src/systematic-completion/components/expandable-table/types/IExpandableSection'
import { IExpandableSectionConfig } from 'src/systematic-completion/components/expandable-table/types/IExpandableSectionConfig'
import { IHeader } from 'src/systematic-completion/components/expandable-table/types/IHeader'
import { IRow } from 'src/systematic-completion/components/expandable-table/types/IRow'

const ChecklistExecutable = ({ checklist }: { checklist: IChecklist }) => {
  const [rows, setRows] = useState<Record<string, IRow>>({})
  const [activeDataType, setActiveDataType] = useState<BASE_METADATA_TYPES>()
  const projectId = useProjectId()
  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: [checklistQueryKey] })
  }
  const { t } = useTranslation()

  const executionStarted = useMemo(
    () => checklist?.status !== ChecklistStatus.NOT_STARTED,
    [checklist?.status],
  )
  const executionCompleted = useMemo(
    () => checklist?.status === ChecklistStatus.COMPLETED,
    [checklist?.status],
  )

  useEffect(() => {
    if (!checklist) return
    createTable(
      checklist.checklist_groups ?? [],
      checklist.checklist_groups
        ? checklist.checklist_groups.flatMap(
            (group) => group.checklist_items ?? [],
          )
        : [],
    )
  }, [checklist])

  const [expandableSections, setExpandableSections] = useState<
    IExpandableSection[]
  >([])

  const [expandableSectionsConfig, setExpandableSectionsConfig] = useState<
    Record<string, IExpandableSectionConfig>
  >({})

  const onItemUpdated = ({ header, rowId, newValue }: ICellUpdate) => {
    return updateCheckListItem(+rowId, { [header.field]: `${newValue}` })
  }

  const headers = useMemo(
    (): IHeader[] => [
      {
        id: 'name',
        label: capitalize(t('name')),
        field: 'name',
        initialWidth: 400,
        minWidth: 400,
        type: COLUMN_TYPES.CUSTOM,
        draggable: false,
        cell: ({ value: name }: { value: string }) => {
          return <div className={'p-2'}>{name}</div>
        },
      },
      {
        id: 'data_value',
        label: capitalize(t('value')),
        field: 'data_value',
        initialWidth: 400,
        minWidth: 400,
        type: COLUMN_TYPES.CUSTOM,
        draggable: false,
        cell: ({ row }: { row: IChecklistItem }) => {
          return (
            <ChecklistValueCell
              disabled={executionCompleted || !executionStarted}
              item={row}
              onItemUpdate={(updatedItem) =>
                updateCheckListItem(row.id, updatedItem)
              }
            />
          )
        },
      },
    ],
    [t, checklist],
  )

  const createTable = (
    checklistGroups: IChecklistGroup[],
    checkListItems: IChecklistItem[],
  ) => {
    const expandableSectionsConfigTmp: Record<
      string,
      IExpandableSectionConfig
    > = {}

    checklistGroups.map((group) => {
      expandableSectionsConfigTmp[group.id] = {
        id: group.id,
        isExpanded: true,
        rows: checkListItems
          .filter((item) => item.checklist_group_id === group.id)
          .map((item) => item.id!)
          .filter(Boolean),
        title: group.name,
      }
    })

    const expandableSectionsTmp = checklistGroups.map((e) => {
      return { id: e.id }
    })

    setRows(
      checkListItems.reduce((acc, checkListItem) => {
        acc[checkListItem.id] = checkListItem
        return acc
      }, {}),
    )
    if (!activeDataType)
      setActiveDataType(
        checkListItems[checkListItems.length - 1]?.data_type ??
          BASE_METADATA_TYPES.string,
      )
    setExpandableSectionsConfig(expandableSectionsConfigTmp)
    setExpandableSections(expandableSectionsTmp)
  }

  const { store, interactionStore } = useExpandableTable(`checklist-table`, {
    onCellUpdated: onItemUpdated,
    partialStore: {
      headers,
      rows,
      expandableSections,
      expandableSectionsConfig,
      readonly: true,
      itemTitle: 'checklist_item',
      sectionTitle: 'checklist_grouping',
      addSectionTitle: 'add_grouping',
      randomNumber: false,
      isRowSelectable: false,
    },
  })

  return (
    <div className="p-2">
      <ChecklistActionButtons checklist={checklist} />
      <ExpandableTable
        store={store}
        interactionStore={interactionStore}
        canReorderRows={false}
        projectId={projectId}
        readonly={true}
        reload={reload}
      />
    </div>
  )
}

export default ChecklistExecutable
