export const constructionTaskTypeImports = {
  name: 'construction_task_type_import_template',
  displayName: 'construction_task_type_import_template',
  columns: [
    'train_template',
    'wagon_template',
    'duration_days',
    'clean_status',
    'activity_template',
    'discipline',
    'unit',
    'total_units',
    'hours_per_unit',
    'productivity_factor',
    'working_hours',
    'duration_hours',
    'number_of_workers',
  ],
  template: [
    {
      name: 'train_template',
      required: false,
      value: 'text',
      description: 'train_template',
      example: 'Test tog',
    },
    {
      name: 'wagon_template',
      required: false,
      value: 'text',
      description: 'wagon_template',
      example: 'Test Vogn',
    },
    {
      name: 'duration_days',
      required: false,
      value: 'text',
      description: 'duration_days',
      example: '1',
    },
    {
      name: 'clean_status',
      required: false,
      value: 'text',
      description: 'clean_status_values',
      example: 'Ikke ren sone',
    },
    {
      name: 'activity_template',
      required: true,
      value: 'text',
      description: 'activity_template',
      example: 'Aktivitet tittle',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_imported_item',
      example: 'BH',
    },
    {
      name: 'unit',
      required: false,
      value: 'text',
      description: 'unit_values',
      example: 'meter',
    },
    {
      name: 'total_units',
      required: false,
      value: 'text',
      description: 'total_units',
      example: '1',
    },
    {
      name: 'hours_per_unit',
      required: false,
      value: 'text',
      description: 'hours_per_unit',
      example: '1',
    },
    {
      name: 'productivity_factor',
      required: false,
      value: '0 - 1',
      description: 'productivity_factor ',
      example: '0.9',
    },
    {
      name: 'working_hours',
      required: false,
      value: 'text',
      description: 'working_hours',
      example: '1',
    },
    {
      name: 'duration_hours',
      required: false,
      value: 'text',
      description: 'duration_hours',
      example: '1',
    },
    {
      name: 'number_of_workers',
      required: false,
      value: 'text',
      description: 'number_of_workers',
      example: '234',
    },
  ],
}
