import Delete from '@icons/delete.svg'
import RestartAlt from '@icons/restart_alt.svg'
import Save from '@icons/save.svg'
import Table from '@icons/table.svg'
import TableView from '@icons/table_view.svg'
import Update from '@icons/update.svg'
import Add from '@material-symbols/svg-500/rounded/add.svg'
import { Table as ReactTable, TableState } from '@tanstack/react-table'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import useProjectId from 'src/components/hooks/useProjectId'
import useShortcut from 'src/components/hooks/useShortCut'
import useUserId from 'src/components/hooks/useUserId'
import useReloadTableConfig from 'src/hooks/useReloadTableConfig'
import {
  useDeleteNamedConfig,
  useMutateNamedConfig,
  useClearTableConfig,
  useTableConfig,
  useMutateTableConfig,
  useNamedConfigs,
} from 'src/query/tableConfig'
import TableConfigFormModal from 'src/ui-elements/Table/TableConfigFormModal'
import {
  NonColumnFilters,
  SavedTableConfig,
  TableKeeperState,
} from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import ButtonWithDropdownMenuMultipleActions from 'src/ui-elements/button/ButtonWithDropdownMenuMultipleActions'
import BackgroundLoader from 'src/ui-elements/loader/BackgroundLoader'

export enum DataModel {
  UNKNOWN = 'Unknown',
  SYSTEM = 'System',
  SYSTEM_IMPORT_HISTORY = 'SystemImportHistory',
  TEST_WORK_GROUP = 'TestWorkGroup',
  TEST_SYSTEM_GROUP = 'TestSystemGroup',
  FILE_CONTAINER = 'FileContainer',
  TEST = 'Test',
  REQUIREMENT = 'Requirement',
  STANDARD_REQUIREMENT = 'StandardRequirement',
  TASK = 'Task',
  DELIVERY = 'Delivery',
  KEY_POINT = 'KeyPoint',
  MILESTONE = 'Milestone',
  IMPROVEMENT = 'Improvement',
  CONTRACT = 'Contract',
  CHECKLIST = 'Checklist',
  CHECKLIST_TEMPLATE = 'ChecklistTemplate',
  DRAFT = 'Draft',
}

type ITableConfigButtonProps<T> = {
  table: ReactTable<T>
  tableName: string
  modelName: DataModel
  nonColumnFilters?: NonColumnFilters
  setNonColumnFilters?: (filters: NonColumnFilters) => void
}

export interface ITableConfigProps {
  name: string
}

const TableConfigButtons = <T extends { id: number | string }>({
  tableName,
  modelName,
  table,
  nonColumnFilters,
  setNonColumnFilters,
}: ITableConfigButtonProps<T>) => {
  const [showCreateConfigModal, setShowCreateConfigModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const projectId = useProjectId()
  const userId = useUserId()

  const { data: activeConfig } = useTableConfig<
    TableState & { activeNamedConfig?: string }
  >(tableName)
  const { mutate: mutateActiveConfig } = useMutateTableConfig(tableName)

  const { mutate: clearTableConfig } = useClearTableConfig(tableName)

  const { data: namedConfigs = [] } =
    useNamedConfigs<SavedTableConfig[]>(modelName)
  const { mutate: deleteNamedConfig } = useDeleteNamedConfig(modelName)
  const { mutateAsync: mutateNamedConfig } = useMutateNamedConfig(
    modelName,
    () => {
      setShowCreateConfigModal(false)
    },
  )

  const activeNamedConfig = useMemo(
    () => namedConfigs.find((c) => c.name === activeConfig?.activeNamedConfig),
    [activeConfig, namedConfigs],
  )

  const invalidate = useReloadTableConfig(tableName)

  const tableState = table.getState()
  const copyTableState = useCallback(() => {
    return {
      columnVisibility: tableState.columnVisibility,
      sorting: tableState.sorting,
      filters: tableState.columnFilters,
      columnOrdering: tableState.columnOrder,
      columnSizing: tableState.columnSizing,
      nonColumnFilters: nonColumnFilters,
    } as TableKeeperState
  }, [tableState, nonColumnFilters])

  const setLoadingWithTimeout = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    setLoading(true)
  }

  const applyNamedConfig = (config: SavedTableConfig) => {
    setLoadingWithTimeout()
    if (config.name !== activeConfig?.activeNamedConfig) {
      mutateActiveConfig({ ...config.config, activeNamedConfig: config.name })
    }
    table.setColumnVisibility(config.config.columnVisibility ?? {})
    table.setSorting(config.config.sorting ?? [])
    table.setColumnFilters(config.config.filters ?? [])
    table.setColumnOrder(config.config.columnOrdering ?? [])
    table.setColumnSizing(config.config.columnSizing ?? {})
    setNonColumnFilters?.(config.config.nonColumnFilters ?? {})
  }

  const cleanConfig = (config?: TableKeeperState) => {
    if (!config) return null
    return JSON.stringify({
      columnVisibility: config.columnVisibility,
      sorting: config.sorting,
      filters: config.filters,
      columnOrdering: config.columnOrdering,
      columnSizing: config.columnSizing,
      nonColumnFilters: config.nonColumnFilters,
    })
  }

  const sessionConfigIsDirty = useMemo(() => {
    const cleanedActiveConfig = cleanConfig(activeConfig)
    const cleanedSessionConfig = cleanConfig(copyTableState())
    return !isEqual(cleanedActiveConfig, cleanedSessionConfig)
  }, [copyTableState, activeConfig])

  const saveActiveConfig = (name?: string) => {
    setLoadingWithTimeout()
    const config = copyTableState()
    mutateActiveConfig({
      ...config,
      activeNamedConfig: name,
      pagination: table.getState().pagination,
    })
  }

  const saveNamedConfig = async (name: string) => {
    const config = copyTableState()
    await mutateNamedConfig({
      config,
      name,
      public: false,
      userId,
      modelName,
      projectId,
    })
    invalidate(tableName)
    saveActiveConfig(name)
  }

  const onSaveNamedConfig: SubmitHandler<ITableConfigProps> = async ({
    name,
  }) => {
    await saveNamedConfig(name)
  }

  const onOverrideNamedConfig = async () => {
    if (!activeNamedConfig) return
    await saveNamedConfig(activeNamedConfig.name)
  }

  const clearTableConfigurations = () => {
    table.resetSorting()
    table.resetColumnFilters()
    table.resetColumnSizing()
    table.resetColumnVisibility()
    table.resetColumnOrder()
    clearTableConfig(tableName)
  }

  useShortcut(['Shift', 'S'], saveActiveConfig)

  return (
    <>
      <div className="flex items-center">
        <div className="relative">
          <ButtonWithDropdownMenu
            type={Button.ButtonType.SECONDARY}
            title={t('table_configuration_settings')}
            items={[
              ...(sessionConfigIsDirty
                ? [
                    {
                      icon: <Save className="text-md mr-1" />,
                      title: t('set_active_configuration'),
                      onClick: saveActiveConfig,
                      shortcut: t('cmd_s', {
                        cmd: '⇧',
                      }),
                    },
                  ]
                : []),
              {
                icon: <Add className="text-md mr-1" />,
                title: t('new_named_configuration'),
                onClick: () => setShowCreateConfigModal(true),
              },
              {
                icon: <Update className="text-md mr-1" />,
                title: t('override_current_named_configuration'),
                onClick: () => onOverrideNamedConfig(),
                disabled: !activeNamedConfig,
                disabledMessage: t('no_active_named_configuration'),
              },
              {
                icon: <RestartAlt className="text-md mr-1" />,
                title: `${t('clear_table_configuration_for_this_table')}`,
                onClick: () => clearTableConfigurations(),
              },
            ]}
          >
            <Table className={'text-xl'} />
          </ButtonWithDropdownMenu>
          {sessionConfigIsDirty && !loading && (
            <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-600"></span>
          )}
        </div>
        <ButtonWithDropdownMenuMultipleActions
          disabled={namedConfigs.length < 1}
          title={`${t('named_configurations')}`}
          items={
            namedConfigs.length > 0
              ? namedConfigs.map((c) => ({
                  icon: <Delete className={'fill-red text-sm'} />,
                  active: c.name === activeConfig?.activeNamedConfig,
                  onClickIcon: () => deleteNamedConfig(c.name),
                  title: c.name,
                  onClick: () => applyNamedConfig(c),
                }))
              : []
          }
        >
          <TableView className="text-xl" />
        </ButtonWithDropdownMenuMultipleActions>
      </div>
      {showCreateConfigModal && (
        <TableConfigFormModal
          tableConfigs={namedConfigs}
          closeModal={() => setShowCreateConfigModal(false)}
          showModal={showCreateConfigModal}
          onSave={onSaveNamedConfig}
        />
      )}

      <BackgroundLoader
        active={loading}
        loadingText={t('saving_table_config')}
      />
    </>
  )
}

export default TableConfigButtons
