export const objectImportTemplate = {
  name: 'system_import_template',
  displayName: 'system_import_template',
  columns: [
    'system_number',
    'updated_system_number',
    'name',
    'description',
    'disciplines',
    'system_mmi',
    'system_fmi',
    'object_status',
    'test_system_group',
    'active',
    'is_testable',
    'is_in_bim',
    'replaced_by',
    'location',
    'floor',
    'room',
    'coverage_room',
  ],
  template: [
    {
      name: 'system_number',
      required: true,
      value: 'text',
      description: 'system_number',
      example: 'XXX.XXXX.XXXX',
    },
    {
      name: 'updated_system_number',
      required: false,
      value: 'text',
      description: 'updated_system_number',
      example: 'XXX.XXXX.XXXX',
    },
    {
      name: 'name',
      required: true,
      value: 'text',
      description: 'name',
      example: '',
    },
    {
      name: 'description',
      required: true,
      value: 'text',
      description: 'description_of_imported_item',
      example: 'Beskrivelse',
    },
    {
      name: 'disciplines',
      required: true,
      value: 'text',
      description: 'discipline_of_imported_item',
      example: 'CAN-TST, BH',
    },
    {
      name: 'system_mmi',
      required: false,
      value: 'text',
      description: 'system_mmi',
      example: '',
    },
    {
      name: 'system_fmi',
      required: false,
      value: 'text',
      description: 'system_fmi',
      example: '',
    },
    {
      name: 'object_status',
      required: false,
      value: 'text',
      description: 'object_status',
      example: 'status',
    },
    {
      name: 'test_system_group',
      required: false,
      value: 'text',
      description: 'test_system_group',
      example: 'test',
    },
    {
      name: 'active',
      required: true,
      value: 'boolean',
      description: 'yes_or_no',
      example: 'ja',
    },
    {
      name: 'is_testable',
      required: false,
      value: 'boolean',
      description: 'yes_or_no',
      example: 'ja',
    },
    {
      name: 'is_in_bim',
      required: false,
      value: 'boolean',
      description: 'yes_or_no',
      example: 'ja',
    },
    {
      name: 'replaced_by',
      required: false,
      value: 'text',
      description: 'replaced_by',
      example: '',
    },
    {
      name: 'location',
      required: false,
      value: 'text',
      description: 'location',
      example: '',
    },
    {
      name: 'room',
      required: false,
      value: 'text',
      description: 'room functional number',
      example: 'FR 12',
    },
    {
      name: 'coverage_room',
      required: false,
      value: 'text',
      description: 'coverage room functional number',
      example: 'FR 34',
    },
  ],
}
