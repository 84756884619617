import NavigateNext from '@icons/navigate_next.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import ChecklistDetailPage from 'src/components/checklist/checklist/ChecklistDetailPage'
import ChecklistTable from 'src/components/checklist/checklist/ChecklistTable'
import { useChecklist } from 'src/query/checklist'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import { classNames } from 'src/utility/utils'

interface IChecklistDetailRouterProps {
  parentId?: number
  parentType?: string
  parentFilter?: TableFilter
  writeAccess?: boolean
  parentDetailUrl?: string
}

const ChecklistRouter = ({
  parentId,
  parentType,
  parentFilter,
  writeAccess,
  parentDetailUrl,
}: IChecklistDetailRouterProps) => {
  const { path: basePath } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const [selectedChecklistId, setSelectedChecklistId] = useState<number>()

  const { data } = useChecklist(selectedChecklistId)

  const navigateToTable = () => {
    setSelectedChecklistId(undefined)
    const params = new URLSearchParams(location.search)
    params.delete('checklistId')

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const { t } = useTranslation()
  return (
    <div>
      <div className={twMerge('flex flex-wrap items-center text-sm')}>
        <div
          onClick={navigateToTable}
          className={classNames(
            data
              ? 'text-d-fontgrey hover:text-blue-root-lighter transition duration-150 ease-in-out cursor-pointer'
              : 'text-blue-root-lighter font-medium',
          )}
        >
          {t('checklists')}
        </div>
        {data && <NavigateNext className="mx-1 fill-d-fontgrey" />}
        <div className={'text-blue-root-lighter font-medium'}>{data?.name}</div>
      </div>
      <Route
        exact
        path={basePath}
        render={(routeProps) => {
          const searchParams = new URLSearchParams(routeProps.location.search)
          const checklistId = searchParams.get('checklistId')

          if (checklistId) {
            setSelectedChecklistId(+checklistId)
            return (
              <ChecklistDetailPage
                writeAccess={writeAccess}
                checklistId={+checklistId}
              />
            )
          }
          return (
            <ChecklistTable
              key={1}
              parentDetailUrl={parentDetailUrl}
              writeAccess={writeAccess}
              parentFilter={parentFilter}
              parentId={parentId}
              parentType={parentType}
            />
          )
        }}
      />
    </div>
  )
}

export default ChecklistRouter
