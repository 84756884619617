import { useTranslation } from 'react-i18next'
import ChecklistTemplateExpandableTable from 'src/components/checklist/checklist-template/ChecklistTemplateExpandableTable'
import { IChecklist } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface EditChecklistModalProps {
  closeModal: () => void
  show: boolean
  checklist: IChecklist
}

const EditChecklistModal = ({
  closeModal,
  show,
  checklist,
}: EditChecklistModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      maxWidth={1100}
      title={`${t('edit')} ${t('checklist').toLowerCase()}`}
      closeModal={closeModal}
      show={show}
    >
      <ChecklistTemplateExpandableTable
        checklist={checklist}
        update_key={'checklist_id'}
      />
      <ModalFooter>
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('close')}
          </Button>
        </ModalFooter>
      </ModalFooter>
    </Modal>
  )
}

export default EditChecklistModal
