export const wagonImportTemplate = {
  name: 'wagon_import_template',
  displayName: 'wagon_import_template',
  columns: [
    'title',
    'description',
    'start_date',
    'construction_train',
    'discipline',
    'set_duration_(days)',
    'clean_status',
  ],
  template: [
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_imported_item',
      example: 'Tittel til wagon',
    },
    {
      name: 'description',
      required: true,
      value: 'text',
      description: 'description_of_imported_item',
      example: 'Beskrivelse til wagon',
    },
    {
      name: 'start_date',
      required: true,
      value: 'date_mm_dd_yyyy',
      description: 'start_date',
      example: '03.06.2021',
    },
    {
      name: 'construction_train',
      required: true,
      value: 'text',
      description: 'construction_train',
      example: 'Test tog 35',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_imported_item',
      example: 'CAN-TST',
    },
    {
      name: 'set_duration_(days)',
      required: false,
      value: 'text',
      description: 'set_duration_(days)',
      example: 1,
    },
    {
      name: 'clean_status',
      required: true,
      value: 'text',
      description: 'clean_status_values',
      example: 'Ikke ren sone',
    },
  ],
}
