import { useEffect, useState } from 'react'
import TableColumnWidthRowHeightHandler from '../services/TableColumnWidthRowHeightHandler'
import {
  createExpandableInteractionStore,
  IExpandableTableInteractionStore,
} from '../stores/ExpandableTableInteractionStore'
import {
  createExpandableStore,
  IExpandableTableStore,
} from '../stores/ExpandableTableStore'
import { ICellUpdate } from '../types/ICellUpdate'
import { IExpandableSection } from '../types/IExpandableSection'
import { IHeader } from '../types/IHeader'
import { IRow } from '../types/IRow'

export const useExpandableTable = (
  tableName: string,
  options: {
    onCellUpdated: (update: ICellUpdate) => void
    createRow?: (
      sectionId: IExpandableSection['id'],
      newRow: IRow,
      rowIndex?: number,
    ) => Promise<number>
    createSection?: (title: string) => Promise<number>
    deleteSection?: (sectionId: number) => void
    deleteRow?: (rowId: string) => void
    onSectionTitleUpdate?: (
      sectionId: IExpandableSection['id'],
      newTitle: string,
    ) => void
    onReorderRows?: (
      row: IRow,
      oldIndex: number,
      newIndex: number,
      oldSectionId: IExpandableSection['id'],
      newSectionId: IExpandableSection['id'],
    ) => void
    onReorderColumns?: (
      header: IHeader,
      oldIndex: number,
      newIndex: number,
    ) => void
    onSelectCallback?: (
      selectedRowIds: number[],
      selectedSectionIds: number[],
    ) => void
    partialStore?: Partial<IExpandableTableStore>
  },
) => {
  const {
    createRow,
    onCellUpdated,
    onReorderRows,
    onReorderColumns,
    onSectionTitleUpdate,
    deleteRow,
    partialStore,
    createSection,
    deleteSection,
    onSelectCallback,
  } = options

  const [store] = useState(() =>
    createExpandableStore(
      {
        createRow,
        deleteRow,
        createSection,
        deleteSection,
        onCellUpdated,
        onReorderColumns,
        onReorderRows,
        onSectionTitleUpdate,
        onSelectCallback,
      },
      partialStore,
    ),
  )
  const [interactionStore] = useState(() =>
    createExpandableInteractionStore(
      {
        numberOfColumns: partialStore?.headers?.length ?? 0,
        numberOfRows: Object.keys(partialStore?.rows ?? {}).length,
      },
      tableName,
    ),
  )

  useEffect(() => {
    const tmpStore = { ...store.getState() }
    const mergedStore = { ...tmpStore, ...partialStore }

    store.setState(mergedStore)

    if (partialStore?.headers || partialStore?.rows) {
      const partialInteractionStore: Partial<IExpandableTableInteractionStore> =
        {
          numberOfColumns: partialStore.headers?.length,
          numberOfRows: Object.keys(partialStore.rows ?? {}).length,
        }

      const tmpInteractionStore = { ...interactionStore.getState() }
      const mergedInteractionStore = {
        ...tmpInteractionStore,
        ...partialInteractionStore,
      }

      const { headers: widthPerCol, rows: heightPerRow } =
        TableColumnWidthRowHeightHandler.initializeTable(
          partialStore.headers as IHeader[],
          partialStore.rows as Record<string, IRow>,
          partialStore.readonly as boolean,
          tableName,
          40,
        )

      mergedInteractionStore.widthPerCol = widthPerCol
      mergedInteractionStore.heightPerRow = heightPerRow

      interactionStore.setState(mergedInteractionStore)
    }
  }, [interactionStore, partialStore, store, tableName])

  return {
    store,
    interactionStore,
  }
}
