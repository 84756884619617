import { capitalize } from 'lodash'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ChecklistTemplateSelectList from 'src/components/checklist/checklist-template/ChecklistTemplateSelectList'
import { ChecklistStatus } from 'src/components/checklist/utils'
import SelectorWithSelectModal from 'src/components/selectors/SelectorWithModal'
import { useMutateChecklist } from 'src/query/checklist'
import { ICreateChecklist } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface IChecklistProps {
  onCloseModal: (close: boolean) => void
  show: boolean
  parentId: number
  parentType: string
}
const CreateChecklistModal = ({
  onCloseModal,
  show,
  parentId,
  parentType,
}: IChecklistProps) => {
  const { t } = useTranslation()
  const [showTemplates, setShowTemplates] = useState(false)
  const { control, setError, handleSubmit } = useForm<
    Partial<ICreateChecklist>
  >({
    defaultValues: {
      status: ChecklistStatus.NOT_STARTED,
      parent_id: parentId,
      parent_type: parentType,
    },
  })

  const { createList } = useMutateChecklist({
    setError: setError,
    onSuccess: () => onCloseModal(false),
  })
  const create = (data: ICreateChecklist) => {
    createList.mutate({
      ...data,
      checklist_template_id: data.checklist_template?.id,
      checklist_template: undefined,
    })
  }

  return (
    <div>
      <Modal
        title={t('new_checklist')}
        show={show}
        maxWidth={500}
        closeModal={() => onCloseModal(false)}
      >
        <Controller
          name={'name'}
          rules={{ required: t('required') }}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              label={capitalize(t('name'))}
              value={value}
              onChange={onChange}
              placeholder={t('name')}
              errorMessage={error?.message ? t(error.message) : ''}
              required
              block
            />
          )}
        />
        <Controller
          name={'checklist_template'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectorWithSelectModal
              setShowModal={setShowTemplates}
              showModal={showTemplates}
              resetValue={() => {
                onChange(undefined)
              }}
              selectedItem={
                value ? { id: value.id, value: value.name } : undefined
              }
              placeholder={t('choose_checklist_template')}
              label={t('checklist_template')}
              tooltipText={t('choose_checklist_template')}
            >
              <Modal
                height={500}
                show={showTemplates}
                title={t('choose_checklist_template')}
                closeModal={() => setShowTemplates(false)}
              >
                <ChecklistTemplateSelectList
                  onSelect={(row) => {
                    setShowTemplates(false)
                    onChange(row)
                  }}
                />
                <ModalFooter>
                  <Button onClick={() => setShowTemplates(false)}>
                    {t('cancel')}
                  </Button>
                </ModalFooter>
              </Modal>
            </SelectorWithSelectModal>
          )}
        ></Controller>
        <ModalFooter>
          <Button onClick={() => onCloseModal(false)}>{t('cancel')}</Button>
          <Button onClick={handleSubmit(create)} type={ButtonType.PRIMARY}>
            {t('save')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CreateChecklistModal
