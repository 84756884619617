import { TFunction } from 'i18next'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

export const checkListTemplateStatusColorMapper: {
  [status in ChecklistTemplateStatus]: BadgeColor
} = {
  [ChecklistTemplateStatus.DRAFT]: BadgeColor.BLUE,
  [ChecklistTemplateStatus.ACTIVE]: BadgeColor.GREEN,
  [ChecklistTemplateStatus.ARCHIVED]: BadgeColor.DEFAULT,
}

export const checklistTemplateStatus = (
  t: TFunction<'translation'>,
): { id: string; name: string }[] => [
  {
    id: ChecklistTemplateStatus.DRAFT,
    name: t('draft'),
  },
  {
    id: ChecklistTemplateStatus.ACTIVE,
    name: t('active'),
  },
  {
    id: ChecklistTemplateStatus.ARCHIVED,
    name: t('archived'),
  },
]

export const checklistTemplateDomainFilter = (
  t: TFunction<'translation'>,
): ISingleFilter[] => [
  {
    value: ChecklistTemplateDomains.SYSTEM,
    name: t('system'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.FDVU,
    name: t('fdvu'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.RISK,
    name: t('risk'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.CONSTRUCTION,
    name: t('construction'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.PLANNING,
    name: t('planning'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.TASK,
    name: t('case'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.DOCUMENT,
    name: t('document'),
    active: true,
  },
]

export const enum ChecklistTemplateStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export const enum ChecklistTemplateDomains {
  TASK = 'case',
  SYSTEM = 'system',
  RISK = 'risk',
  CONSTRUCTION = 'construction',
  PLANNING = 'planning',
  DOCUMENT = 'document',
  FDVU = 'fdvu',
}

export const enum ChecklistStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  INACTIVE = 'inactive',
}

export const checklistStatus = (
  t: TFunction<'translation'>,
): { id: string; name: string }[] => [
  {
    id: ChecklistStatus.NOT_STARTED,
    name: t('not_started'),
  },
  {
    id: ChecklistStatus.COMPLETED,
    name: t('completed'),
  },
  {
    id: ChecklistStatus.INACTIVE,
    name: t('inactive'),
  },
  {
    id: ChecklistStatus.IN_PROGRESS,
    name: t('in_progress'),
  },
]

export const checkListStatusColorMapper: {
  [status in ChecklistStatus]: BadgeColor
} = {
  [ChecklistStatus.IN_PROGRESS]: BadgeColor.BLUE,
  [ChecklistStatus.COMPLETED]: BadgeColor.GREEN,
  [ChecklistStatus.INACTIVE]: BadgeColor.DEFAULT,
  [ChecklistStatus.NOT_STARTED]: BadgeColor.DEFAULT,
}

export const checkListDetailUrl = (
  checklistId: number,
  parentType?: string,
  parentId?: number,
) => {
  const suffixUrl = `checklistId=${checklistId}`
  switch (parentType) {
    case 'TestSystemGroup':
      return `${getDetailUrl.testSystemGroup({ testSystemGroupId: parentId })}&${suffixUrl}`
    case 'TestWorkGroup':
      return `${getDetailUrl.testWorkGroup({ testWorkGroupId: parentId })}&${suffixUrl}`
    default:
      return null
  }
}
