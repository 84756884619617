import Edit from '@icons/edit.svg'
import { useFlag } from '@unleash/proxy-client-react'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import {
  getMetaDataValues,
  initializeMetaValues,
  setExistingValues,
} from 'src/components/system/SystemUtil'
import InspectorPanelSystemList from 'src/components/system/system-table/InspectorPanelSystemList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import Step from 'src/document/components/FolderDocument/Step'
import FilesInspector from 'src/document/components/Folders/FolderInspectorSections/FilesInspector'
import RevisionInspector from 'src/document/components/Folders/FolderInspectorSections/RevisionInspector'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import MetaDataBox from 'src/document/components/MetaDataBox'
import {
  IFileContainer,
  IFileContainerSubmit,
} from 'src/document/types/IFileContainer'
import { IMetaValue } from 'src/document/types/IMetaData'
import { queryClient } from 'src/query/client'
import { useDocumentTypes } from 'src/query/documents/documentType'
import {
  fileContainerKey,
  useFileContainer,
} from 'src/query/documents/fileContainer'
import { useTestDocumentTypes } from 'src/query/systematicCompletion/test'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  editDocument,
  validateDocumentNumber,
  updateFileContainerSystems,
} from 'src/service/FileContainerService'
import {
  getDisciplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import { testDocumentBadgeColorMapper } from 'src/ui-elements/Table/module-columns/DocumentModuleColumns'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'

interface IInspectorPanel {
  documentId: number
  open: boolean
  showRevision?: boolean

  onClose: () => void
  onUpdate?: () => void
}

const DocumentInspectorPanel: React.FC<IInspectorPanel> = ({
  documentId,
  open,
  showRevision,
  onClose,
  onUpdate,
}) => {
  const { t } = useMultipleKeysTranslation()

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [userDefinedValues, setUserDefinedValues] = useState<IMetaValue[]>([])
  const [requiredFields, setRequiredFields] = useState<IMetaValue[]>([])
  const [newRecordId, setNewRecordId] = useState<string>()
  const [newRecordIdError, setNewRecordIdError] = useState<string>()
  const [showRecordIdUpdate, setShowRecordIdUpdate] = useState(false)
  const { data: document, isFetching } = useFileContainer(documentId)
  const { data: documentTypes } = useDocumentTypes()
  const { data: testDocumentTypes } = useTestDocumentTypes()

  const writeAccess = document?.can_edit

  const omegaSyncEnabled = useFlag('OmegaSync')

  const onReloadDocument = () => {
    queryClient.invalidateQueries({ queryKey: [fileContainerKey] })
  }

  const onReloadSystems = () => {
    queryClient.invalidateQueries({ queryKey: ['systemListFiltered'] })
  }

  const onDocumentUpdate = () => {
    onReloadDocument()
    onUpdate?.()
  }

  const onChangeInput = (update: Partial<IFileContainerSubmit>) => {
    editDocument(projectId, documentId, {
      ...update,
      id: documentId,
    }).then((_res: IFileContainer) => {
      onDocumentUpdate()
    })
  }

  const onRequiredMetadataUpdate = () => {
    onChangeInput({ id: documentId })
  }

  useEffect(() => {
    if (document) {
      const metaData = getMetaDataValues(document.meta_data)
      const optional = initializeMetaValues(
        document?.optional_fields ?? [],
        'FileContainer',
        document.id,
        true,
      )
      setUserDefinedValues(setExistingValues(metaData, optional))
      const required = initializeMetaValues(
        document?.required_fields ?? [],
        'FileContainer',
        document.id,
        true,
      )
      setRequiredFields(setExistingValues(metaData, required))
    }
  }, [document])

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4'}>
      <MetaDataBox
        disabled={!document?.can_edit}
        requiredFieldValues={requiredFields}
        optionalFieldValues={userDefinedValues}
        optionalFields={document?.optional_fields ?? []}
        requiredFields={document?.required_fields ?? []}
        inInspector={true}
        onOptionalFieldsUpdate={onDocumentUpdate}
        onRequiredFieldsUpdate={onRequiredMetadataUpdate}
      />
    </div>
  )

  const onRecordIdCancel = () => {
    setShowRecordIdUpdate(false)
    setNewRecordIdError(undefined)
    onReloadDocument()
  }

  const onRecordIdUpdate = () => {
    if (documentId && newRecordId && newRecordId !== document?.record_id) {
      validateDocumentNumber(projectId, documentId, newRecordId).then((res) => {
        if (res.errors) {
          setNewRecordIdError(res.errors)
        } else {
          onRecordIdCancel()
          onUpdate?.()
        }
      })
    } else onRecordIdCancel()
  }

  const getMainContent = () => {
    if (!document) return <></>
    return (
      <InlineComponentsWrapper disableTooltips>
        <div className={'flex items-center'}>
          <TextInlineInputCompontent
            key={isFetching ? '1' : '2'}
            label="document_number"
            value={document?.record_id}
            setValueInParent={setNewRecordId}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('required')
              }
              return undefined
            }}
            disabled={!showRecordIdUpdate}
            autoFocus={showRecordIdUpdate}
            error={newRecordIdError ? t(newRecordIdError) : undefined}
          />
          {!showRecordIdUpdate && writeAccess && (
            <Button
              onClick={() => setShowRecordIdUpdate(true)}
              type={ButtonType.SECONDARY}
            >
              <Edit className={'text-lg'} />
            </Button>
          )}
          {showRecordIdUpdate && (
            <>
              <Button onClick={onRecordIdUpdate} type={ButtonType.PRIMARY}>
                {t('save')}
              </Button>
              <Button onClick={onRecordIdCancel} type={ButtonType.SECONDARY}>
                {t('cancel')}
              </Button>
            </>
          )}
        </div>
        <TextInlineInputCompontent
          disabled={!writeAccess}
          label="title"
          value={document?.name}
          onValueSubmitted={(name) => {
            if (name) onChangeInput({ name })
          }}
        />
        <SelectorInlineInputComponent
          items={documentTypes}
          disabled={!writeAccess}
          label="document_category"
          getItemLabel={(docType) => docType?.name}
          initialItem={document?.document_type}
          validate={(value) => {
            if (value === undefined) return t('required')
            return
          }}
          selectedId={document?.document_type_id}
          onValueSubmitted={(document_type_id) => {
            onChangeInput({ document_type_id })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          items={testDocumentTypes}
          disabled={!writeAccess}
          label="document_type"
          getItemLabel={(docType) => docType?.document_name}
          initialItem={document?.test_document_type}
          selectedId={document?.test_document_type_id}
          onValueSubmitted={(test_document_type_id) => {
            onChangeInput({ test_document_type_id })
          }}
          inspectorPanel={true}
        />
        <InlineCompontent
          disabled
          label="status"
          content={
            document.status ? (
              <Badge
                text={t(document.status)}
                color={testDocumentBadgeColorMapper[document.status]}
              />
            ) : (
              <span />
            )
          }
        />
        <InlineCompontent
          disabled
          label="step"
          content={
            document.current_step ? (
              <Step step={document?.current_step} />
            ) : (
              <span />
            )
          }
        />
        <TextInlineInputCompontent
          label="revision_name"
          value={document?.current_revision?.name}
          onValueSubmitted={(name) => {
            if (name) onChangeInput({ name })
          }}
          disabled={true}
        />
        <DateTimeInlineInputComponent
          label="revision_date"
          selectedTime={document?.current_revision?.start_time}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        {omegaSyncEnabled && (
          <>
            <DateTimeInlineInputComponent
              label="omega_revision_date"
              selectedTime={document?.omega_revision_date}
              onValueSubmitted={(deadline) => {
                onChangeInput({ omega_revision_date: deadline })
              }}
              inspectorPanel={true}
              disabled={!writeAccess}
            />
            <SelectorInlineInputComponent
              getItems={() => Promise.resolve([{ id: 'FI' }, { id: 'SB' }])}
              disabled={!writeAccess}
              label="omega_release_code"
              getItemLabel={(code) => code?.id ?? ''}
              initialItem={
                document?.omega_release_code
                  ? { id: document.omega_release_code }
                  : undefined
              }
              selectedId={document?.omega_release_code}
              onValueSubmitted={(omega_release_code) => {
                onChangeInput({ omega_release_code })
              }}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="synced_to_omega_at"
              selectedTime={document?.synced_to_omega_at}
              onValueSubmitted={() => {}}
              inspectorPanel={true}
              disabled={true}
            />
          </>
        )}
        <DateTimeInlineInputComponent
          label={'document_deadline'}
          selectedTime={document.overdue_date ?? undefined}
          onValueSubmitted={(deadline) => {
            onChangeInput({ overdue_date: deadline ?? null })
          }}
          disabled={!writeAccess}
          inspectorPanel={true}
          cancelButton
        />
        <SelectorInlineInputComponent
          getItems={() => getProjectDisciplines(projectId)}
          disabled={!writeAccess}
          label="discipline"
          initialItem={document?.discipline}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          selectedId={document?.discipline_id}
          onValueSubmitted={(discipline_id) => {
            onChangeInput({ discipline_id })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          getItems={() =>
            document?.discipline_id
              ? getDisciplineUsers(document?.discipline_id)
              : getProjectUsersWithDisciplines(projectId)
          }
          disabled={!writeAccess}
          label="responsible"
          getItemLabel={(responsible) =>
            `${responsible?.firstName ?? ''} ${responsible?.lastName ?? ''}`
          }
          initialItem={document?.responsible}
          selectedId={document?.responsible_id}
          onValueSubmitted={(responsible_id) => {
            onChangeInput({ responsible_id })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          label={'contract'}
          disabled={true}
          selectedId={document?.contract_id ?? ''}
          getItemLabel={(contract) =>
            `${contract?.contractNumber} - ${contract?.contractName}`
          }
          initialItem={document?.contract}
          inspectorPanel={true}
        />

        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={document?.created_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={document?.updated_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }

  const sections: IInspectorSection[] = document
    ? [
        {
          name: t('document_details'),
          icon: Icons.ACTIVITY_GREY,
          activeIcon: Icons.ACTIVITY,
          content: getMainContent(),
          overflowVisible: true,
        },
        {
          name: t('metadata'),
          icon: Icons.DATABASE_GREY,
          activeIcon: Icons.DATABASE,
          content: getMetaDataContent(),
        },
        {
          name: t('systems'),
          icon: Icons.SYSTEM_GRAY,
          activeIcon: Icons.SYSTEM_BLACK,
          content: (
            <InspectorPanelSystemList
              parentFilter={{ file_container_ids: [documentId] }}
              addSystems={async (systemIds) => {
                await updateFileContainerSystems(
                  projectId,
                  documentId,
                  systemIds,
                  [],
                )
                onReloadSystems()
              }}
              removeSystems={async (systemIds) => {
                await updateFileContainerSystems(
                  projectId,
                  documentId,
                  [],
                  systemIds,
                )
                onReloadSystems()
              }}
            />
          ),
        },
        {
          name: t('revisions'),
          icon: Icons.ACTIVITY_GREY,
          activeIcon: Icons.ACTIVITY,
          content: <RevisionInspector document={document} />,
        },
        {
          name: t('files'),
          icon: Icons.FOLDER_GREY,
          activeIcon: Icons.FOLDER,
          content: <FilesInspector document={document} />,
        },
      ]
    : []

  return (
    <FixedPane
      title={`${document?.record_id ?? ''} ${document?.record_id ? '-' : ''}  ${
        document?.name ?? ''
      }`}
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.DOCUMENT,
        ids: {
          folderId: document?.folder_id,
          documentId: documentId,
        },
      }}
    >
      {document ? (
        <InspectorSections
          sections={sections}
          defaultIndex={showRevision ? 2 : 0}
        />
      ) : (
        <></>
      )}
    </FixedPane>
  )
}
export default DocumentInspectorPanel
