import { useQueryClient } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  checklistTemplateDomainFilter,
  checklistTemplateStatus,
  checkListTemplateStatusColorMapper,
} from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import useTree from 'src/hooks/useTree'
import useUserAccess from 'src/hooks/useUserAccess'
import {
  getChecklistTemplatesTree,
  updateCheckListTemplate,
} from 'src/service/ChecklistService'
import { IChecklistTemplate } from 'src/service/OrgTypes'
import {
  defaultDateColumnObject,
  defaultEditableTextColumn,
  defaultFilterColumn,
  defaultUserColumn,
  statusColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'

export const useChecklistTemplateColumns = (writeAccess = true) => {
  const queryClient = useQueryClient()
  const { reloadTree } = useTree(
    ['checklists'],
    getChecklistTemplatesTree,
    'Checklist',
  )
  const projectId = useProjectId()
  const { getUserFilter } = useListHelper()
  const { isProjectAdmin } = useUserAccess()
  const disabled = !isProjectAdmin || !writeAccess

  const columnHelper = createColumnHelper<IChecklistTemplate>()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['filteredChecklistTemplates'] })
    reloadTree()
  }

  const { t } = useTranslation()

  const ct = (s: string) => {
    return capitalize(t(s))
  }

  const update = async (id: number, value: Partial<IChecklistTemplate>) => {
    await updateCheckListTemplate(projectId, id, value)
    reload()
  }

  const columns = [
    columnHelper.accessor(
      'name',
      defaultEditableTextColumn(
        'name',
        { name: ct('name') },
        (id, value) => {
          update(+id, { name: value })
        },
        disabled,
      ),
    ),
    columnHelper.accessor(
      'description',
      defaultEditableTextColumn(
        'description',
        { name: ct('description') },
        (id, value) => {
          update(+id, { description: value })
        },
        disabled,
      ),
    ),
    statusColumn(
      'status',
      ['status'],
      { name: capitalize(t('status')) },
      checklistTemplateStatus(t),
      disabled
        ? undefined
        : (value, key) => {
            update(key, { status: value.id })
          },
      (cell) => {
        return checkListTemplateStatusColorMapper[cell.status]
      },
    ),
    columnHelper.accessor(
      'source',
      defaultEditableTextColumn(
        'source',
        { name: ct('source') },
        (id, value) => {
          update(+id, { source: value })
        },
        disabled,
      ),
    ),
    columnHelper.accessor('domain', {
      ...defaultFilterColumn('domain', {
        name: capitalize(t('domain')),
        getFilter: () => {
          return new Promise((resolve) => {
            resolve(checklistTemplateDomainFilter(t))
          })
        },
      }),
      cell: ({ cell }) => {
        return (
          <span className={'truncate capitalize pl-1'}>
            {t(cell.getValue())}
          </span>
        )
      },
    }),

    columnHelper.accessor(
      'updated_at',
      defaultDateColumnObject('updated_at', { name: ct('updated_at') }),
    ),
    columnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: ct('created_at') }),
    ),
    columnHelper.accessor(
      'updated_by',
      defaultUserColumn('updated_by', {
        name: ct('updated_by'),
        field: 'updated_by.firstName||updated_by.lastName',
        getFilter: getUserFilter,
      }),
    ),
    columnHelper.accessor(
      'created_by',
      defaultUserColumn('created_by', {
        name: ct('created_by'),
        field: 'created_by.firstName||created_by.lastName',
        getFilter: getUserFilter,
      }),
    ),
  ] as ColumnDef<IChecklistTemplate>[]

  const defaultOrdering = [
    'select',
    'name',
    'status',
    'description',
    'source',
    'domain',
    'updated_at',
    'updated_by',
    'created_at',
    'created_by',
  ]

  return { columns, defaultOrdering }
}
