import { FC, Fragment, useContext, useEffect, useState } from 'react'
import SystemUploadSummary from 'src/components/system/SystemUploadSummary'
import { ImportTemplate } from 'src/components/upload-item/ImportHelpers'
import ImportUploadWithVerification from 'src/components/upload-item/ImportWithValidation'
import { useJobId } from 'src/context/ImportContext/hook/useJobId'
import { ImportDataFiled } from 'src/document/components/FileUpload/selectors/DataSelectors'
import {
  IFormatData,
  IValidationResponse,
} from 'src/document/components/FileUpload/types'
import { ImportContext } from '../../context/ImportContext/ImportContext'
import {
  ImportStatus,
  ImportTask,
} from '../../context/ImportContext/ImportTypes'
import { addUserDefinedInfoToImport } from '../../utility/exportUtils'
import useProjectId from '../hooks/useProjectId'
import { IMPORT_TYPE } from '../notifications/import/ImportNotificationItem'
import { mergeMultipleChanges } from '../system/SystemUtil'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'

interface ImportModalProps {
  show: boolean
  close: () => void
  import_type: IMPORT_TYPE
  modal_title: string
  template: ImportTemplate
  columns?: ImportDataFiled[]
  useDefinedField?: IMetaField[]
  importApi: (projectId: number, data: any) => Promise<ImportTask>
  uploadUrl: string
  JobId?: number
}

const ImportModal: FC<ImportModalProps> = ({
  show,
  close,
  import_type,
  modal_title,
  template,
  columns,
  useDefinedField,
  importApi,
  uploadUrl,
  JobId,
}) => {
  const [jobId, setJobId] = useState<number | undefined>(JobId)
  const [currentStep, setCurrentStep] = useState<number>()
  const [pollingResponse, setPollingResponse] = useState<IValidationResponse>()

  const { status, data } = useJobId(jobId)
  const projectId = useProjectId()

  const { addImportTask } = useContext(ImportContext).actions

  useEffect(() => {
    if (status && status !== ImportStatus.IN_PROGRESS) {
      setPollingResponse(data as IValidationResponse)
      setCurrentStep(status === ImportStatus.SUCCESS ? 2 : 1)
    }
  }, [status, data])

  const formatResponseData = (responseData: any) => {
    const job = responseData.id
    setJobId(job)
    addImportTask(responseData)

    return responseData.job_data as IValidationResponse
  }

  const validateMultiple = async (
    newValues: Record<string, string | string[] | null>[],
    validRes: IValidationResponse,
  ) => {
    const data = mergeMultipleChanges(newValues, validRes[import_type] ?? [])

    const res = await importApi(projectId, {
      [import_type]: data,
      job_id: jobId,
    })
    addImportTask(res)

    return res.job_data as IValidationResponse
  }

  const uploadObjectToRemote = async (data: IFormatData[]) => {
    const res = await importApi(projectId, {
      [import_type]: data,
      job_id: jobId,
    })

    return addImportTask(res)
  }

  if (status === ImportStatus.FAILED) {
    close()
    return <Fragment />
  }

  if (status === ImportStatus.IN_PROGRESS) {
    return <Fragment />
  }

  return (
    <ImportUploadWithVerification
      show={show}
      close={close}
      uploadUrl={uploadUrl}
      importTemplate={addUserDefinedInfoToImport(
        template,
        [],
        useDefinedField ?? [],
      )}
      useDefinedFiled={useDefinedField}
      formatResponseData={formatResponseData}
      validateMultiple={validateMultiple}
      parentId={projectId}
      title={modal_title}
      type={import_type}
      currentStep={currentStep}
      columns={columns}
      responseData={pollingResponse}
      uploadData={uploadObjectToRemote}
      confirmStep={(uploadRef) => {
        if (!uploadRef) return <span />
        return (
          <SystemUploadSummary importType={import_type} summary={uploadRef} />
        )
      }}
    />
  )
}

export default ImportModal
