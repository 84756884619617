export type UrlParams<Key extends string = string> = {
  readonly [key in Key]: string | undefined
}

export enum DetailPageKeys {
  CHECKLIST_TEMPLATE = 'checklist_template',
  CONTRACT = 'contract',
  CONTRACTUAL_BORDER = 'contractualBorder',
  CONTROL_AREA = 'controlArea',
  DELIVERY = 'delivery',
  DELIVERY_UNDER_KEYPOINT = 'delivery_under_keypoint',
  DELIVERY_UNDER_MILESTONE_KEYPOINT = 'deliveryUnderMilestoneKeypoint',
  DISCIPLINE = 'discipline',
  DOCUMENT = 'document',
  KEYPOINT = 'keypoint',
  KEYPOINT_UNDER_MILESTONE = 'keypointUnderMilestone',
  MEETING_SERIES = 'meetingSeries',
  MILESTONE = 'milestone',
  REQUIREMENT = 'requirement',
  RISK = 'risk',
  RISK_GROUP = 'riskGroup',
  ROOM = 'room',
  SYSTEM = 'system',
  SYSTEM_UNDER_TEST_SYSTEM_GROUP = 'systemUnderTestSystemGroup',
  SYSTEM_UNDER_TEST_WORK_GROUP = 'systemUnderTestWorkGroup',
  TASK = 'task',
  TASK_UNDER_CASE_TYPE = 'taskUnderCaseType',
  TASK_UNDER_DELIVERY = 'taskUnderDelivery',
  TASK_UNDER_KEYPOINT_DELIVERY = 'taskUnderKeyPointDelivery',
  TASK_UNDER_MILESTONE_KEYPOINT_DELIVERY = 'taskUnderMilestoneKeyPointDelivery',
  TASK_WITHOUT_CASE_TYPE = 'taskWithoutCaseType',
  SUBTASK = 'subtask',
  TEST = 'test',
  TEST_SYSTEM_GROUP = 'testSystemGroup',
  TEST_SYSTEM_GROUP_SYSTEM = 'testSystemGroupSystem',
  TEST_TYPE = 'testType',
  TEST_WORK_GROUP = 'testWorkGroup',
  TEST_WORK_GROUP_TEST_SYSTEM_GROUP = 'testWorkGroupTestSystemGroup',
  TEST_WORK_GROUP_SYSTEM = 'testWorkGroupSystem',
  TRAIN = 'train',
  TRAIN_TYPE = 'trainType',
  WAGON = 'wagon',
  WAGON_TYPE = 'wagonType',
}

type DetailPageIdTypes = {
  [DetailPageKeys.CHECKLIST_TEMPLATE]: {
    checklistTemplateId: number | undefined
    domain: string | undefined
    baseBath?: string
  }
  [DetailPageKeys.CONTRACT]: { contractId: number | undefined }
  [DetailPageKeys.CONTRACTUAL_BORDER]: {
    contractualBorderId: number | undefined
  }
  [DetailPageKeys.CONTROL_AREA]: {
    cagId: number | undefined
    caId: number | undefined
  }
  [DetailPageKeys.DELIVERY]: {
    deliveryId: number | undefined
    params?: UrlParams
  }
  [DetailPageKeys.DELIVERY_UNDER_KEYPOINT]: {
    deliveryId: number | undefined
    keypointId: number | undefined
  }
  [DetailPageKeys.DELIVERY_UNDER_MILESTONE_KEYPOINT]: {
    deliveryId: number | undefined
    keypointId: number | undefined
    milestoneId: number | undefined
  }
  [DetailPageKeys.DISCIPLINE]: {
    contractId: number | undefined
    disciplineId: number | undefined
  }
  [DetailPageKeys.DOCUMENT]: {
    folderId: number | undefined
    documentId: number | undefined
  }
  [DetailPageKeys.KEYPOINT]: {
    keypointId: number | undefined
  }
  [DetailPageKeys.KEYPOINT_UNDER_MILESTONE]: {
    keypointId: number | undefined
    milestoneId: number | undefined
  }
  [DetailPageKeys.MEETING_SERIES]: { meetingSeriesId: number | undefined }
  [DetailPageKeys.MILESTONE]: { milestoneId: number | undefined }
  [DetailPageKeys.REQUIREMENT]: { standardRequirementId: number | undefined }
  [DetailPageKeys.RISK]: {
    riskGroupId: number | undefined
    riskId: number | undefined
  }
  [DetailPageKeys.RISK_GROUP]: { riskGroupId: number | undefined }
  [DetailPageKeys.ROOM]: {
    sectionId: number | undefined
    floorId: number | undefined
    roomId: number | undefined
  }
  [DetailPageKeys.SYSTEM]: {
    path: string | undefined
    systemSettingId: number | undefined
    searchParams: URLSearchParams | undefined
  }
  [DetailPageKeys.SYSTEM_UNDER_TEST_SYSTEM_GROUP]: {
    path: string | undefined
    testSystemGroupId: number | undefined
  }
  [DetailPageKeys.SYSTEM_UNDER_TEST_WORK_GROUP]: {
    path: string | undefined
    testWorkGroupId: number | undefined
    testSystemGroupId: number | undefined
  }
  [DetailPageKeys.TASK]: { taskId: number | undefined; params: UrlParams }
  [DetailPageKeys.TASK_UNDER_DELIVERY]: {
    taskId: number | undefined
    deliveryId: number | undefined
  }
  [DetailPageKeys.TASK_UNDER_KEYPOINT_DELIVERY]: {
    taskId: number | undefined
    keypointId: number | undefined
    deliveryId: number | undefined
  }
  [DetailPageKeys.TASK_UNDER_MILESTONE_KEYPOINT_DELIVERY]: {
    taskId: number | undefined
    milestoneId: number | undefined
    keypointId: number | undefined
    deliveryId: number | undefined
  }
  [DetailPageKeys.SUBTASK]: {
    taskId: number | undefined
    parentTaskId: number | undefined
    taskTypeId: number | undefined
  }
  [DetailPageKeys.TASK_UNDER_CASE_TYPE]: {
    taskTypeId: number | undefined
    taskId: number | undefined
  }
  [DetailPageKeys.TASK_WITHOUT_CASE_TYPE]: {
    taskId: number | undefined
  }
  [DetailPageKeys.TEST]: {
    testTypeId: number | undefined
    testId: number | undefined
  }
  [DetailPageKeys.TEST_SYSTEM_GROUP]: { testSystemGroupId: number | undefined }
  [DetailPageKeys.TEST_SYSTEM_GROUP_SYSTEM]: {
    testSystemGroupId: number | undefined
    systemPath: string | undefined
  }
  [DetailPageKeys.TEST_TYPE]: { testTypeId: number | undefined }
  [DetailPageKeys.TEST_WORK_GROUP]: { testWorkGroupId: number | undefined }
  [DetailPageKeys.TEST_WORK_GROUP_TEST_SYSTEM_GROUP]: {
    testWorkGroupId: number | undefined
    testSystemGroupId: number | undefined
  }
  [DetailPageKeys.TEST_WORK_GROUP_SYSTEM]: {
    testWorkGroupId: number | undefined
    testSystemGroupId: string | undefined
    systemPath: string | undefined
  }

  [DetailPageKeys.TRAIN]: {
    cagId: number | undefined
    caId: number | undefined
    trainId: number | undefined
  }
  [DetailPageKeys.TRAIN_TYPE]: { trainTypeId: number | undefined }
  [DetailPageKeys.WAGON]: {
    cagId: number | undefined
    caId: number | undefined
    trainId: number | undefined
    wagonId: number | undefined
  }
  [DetailPageKeys.WAGON_TYPE]: {
    trainTypeId: number | undefined
    wagonTypeId: number | undefined
  }
}

export interface IDetailPageData<K extends DetailPageKeys> {
  key: K
  ids: DetailPageIdTypes[K]
}

const getSystemRelativePath = (path?: string, pathFromUrl?: string | null) => {
  const pathFromUrlIds = pathFromUrl?.split('/') ?? []
  const firstSystemId = pathFromUrlIds[0]

  const systemPathIds = path?.split('/') ?? []
  const systemId = systemPathIds[systemPathIds.length - 1]

  return firstSystemId
    ? (() => {
        const newPath = `/${path}`.split(`/${firstSystemId}/`)[1]
        return newPath ? `${firstSystemId}/${newPath}` : systemId
      })()
    : systemId
}

export const getDetailUrl = {
  validateIds(ids: Array<number | string | undefined>): boolean {
    for (const key in ids) {
      if (ids[key] === undefined || ids[key] === 0 || ids[key] === '') {
        return false
      }
    }
    return true
  },
  checklistTemplate: (
    ids: DetailPageIdTypes[DetailPageKeys.CHECKLIST_TEMPLATE],
  ) =>
    `${ids.baseBath ?? '/systems/settings'}/checklist_templates/checklist_templates/${ids.domain}/${ids.checklistTemplateId}`,
  contract: (ids: DetailPageIdTypes[DetailPageKeys.CONTRACT]) =>
    `/project/contracts/contracts/${ids.contractId}/`,
  contractualBorder: (
    ids: DetailPageIdTypes[DetailPageKeys.CONTRACTUAL_BORDER],
  ) =>
    `/systematiccompletion/contractual_borders/contractual_borders/${ids.contractualBorderId}/`,
  controlArea: (ids: DetailPageIdTypes[DetailPageKeys.CONTROL_AREA]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/`,
  delivery: (ids: DetailPageIdTypes[DetailPageKeys.DELIVERY]) => {
    if (!ids.params) {
      return `/delivery/table_overview/delivery/${ids.deliveryId}/`
    }
    if (ids.params['milestoneId'] && ids.params['keypointId']) {
      return getDetailUrl.deliveryUnderMilestoneKeypoint({
        deliveryId: ids.deliveryId,
        keypointId: +ids.params['keypointId'],
        milestoneId: +ids.params['milestoneId'],
      })
    } else if (ids.params['keypointId']) {
      return getDetailUrl.deliveryUnderKeypoint({
        deliveryId: ids.deliveryId,
        keypointId: +ids.params['keypointId'],
      })
    }
    return `/delivery/table_overview/delivery/${ids.deliveryId}/`
  },
  deliveryUnderKeypoint: (
    ids: DetailPageIdTypes[DetailPageKeys.DELIVERY_UNDER_KEYPOINT],
  ) =>
    `/keypoint/table_overview/keypoint/${ids.keypointId}/delivery/${ids.deliveryId}/`,
  deliveryUnderMilestoneKeypoint: (
    ids: DetailPageIdTypes[DetailPageKeys.DELIVERY_UNDER_MILESTONE_KEYPOINT],
  ) =>
    `/milestone/overview/milestone/${ids.milestoneId}/keypoint/${ids.keypointId}/delivery/${ids.deliveryId}/`,
  discipline: (ids: DetailPageIdTypes[DetailPageKeys.DISCIPLINE]) =>
    `/project/contracts/contracts/${ids.contractId}/disciplines/${ids.disciplineId}/`,
  document: (ids: DetailPageIdTypes[DetailPageKeys.DOCUMENT]) =>
    `/documents/documents?folderId=${ids.folderId}&documentId=${ids.documentId}`,
  keypoint: (ids: DetailPageIdTypes[DetailPageKeys.KEYPOINT]) =>
    `/keypoint/table_overview/keypoint/${ids.keypointId}/`,
  keypointUnderMilestone: (
    ids: DetailPageIdTypes[DetailPageKeys.KEYPOINT_UNDER_MILESTONE],
  ) =>
    `/milestone/overview/milestone/${ids.milestoneId}/keypoint/${ids.keypointId}/`,
  meetingSeries: (ids: DetailPageIdTypes[DetailPageKeys.MEETING_SERIES]) =>
    `/meetings/meeting_series/meeting_series/${ids.meetingSeriesId}/`,
  milestone: (ids: DetailPageIdTypes[DetailPageKeys.MILESTONE]) =>
    `/milestone/overview/milestone/${ids.milestoneId}/`,
  requirementUnderStandardRequirement: (
    ids: DetailPageIdTypes[DetailPageKeys.REQUIREMENT],
  ) => `/fdvu/requirements?standardRequirementId=${ids.standardRequirementId}`,
  risk: (ids: DetailPageIdTypes[DetailPageKeys.RISK]) =>
    `/risks/overview/risk_groups/${ids.riskGroupId}/risks/${ids.riskId}`,
  riskGroup: (ids: DetailPageIdTypes[DetailPageKeys.RISK_GROUP]) =>
    `/risks/overview/risk_groups/${ids.riskGroupId}`,
  room: (ids: DetailPageIdTypes[DetailPageKeys.ROOM]) =>
    `/sections/${ids.sectionId}/floors/${ids.floorId}/rooms/${ids.roomId}`,
  system: (ids: DetailPageIdTypes[DetailPageKeys.SYSTEM]) => {
    if (
      ids.searchParams?.has('testSystemGroupId') &&
      ids.searchParams?.has('testWorkGroupId')
    ) {
      return getDetailUrl.systemUnderTestWorkGroup({
        path: getSystemRelativePath(
          ids.path,
          ids.searchParams?.get('systemPath'),
        ),
        testSystemGroupId: +ids.searchParams.get('testSystemGroupId')!,
        testWorkGroupId: +ids.searchParams.get('testWorkGroupId')!,
      })
    } else if (ids.searchParams?.has('testSystemGroupId')) {
      return getDetailUrl.systemUnderTestSystemGroup({
        path: getSystemRelativePath(
          ids.path,
          ids.searchParams?.get('systemPath'),
        ),
        testSystemGroupId: +ids.searchParams.get('testSystemGroupId')!,
      })
    }
    return `/systems/systems?systemPath=${ids.path}&systemSettingId=${ids.systemSettingId}`
  },
  systemUnderTestSystemGroup: (
    ids: DetailPageIdTypes[DetailPageKeys.SYSTEM_UNDER_TEST_SYSTEM_GROUP],
  ) =>
    `/systems/test_system_groups?systemPath=${ids.path}&testSystemGroupId=${ids.testSystemGroupId}`,
  systemUnderTestWorkGroup: (
    ids: DetailPageIdTypes[DetailPageKeys.SYSTEM_UNDER_TEST_WORK_GROUP],
  ) =>
    `/systems/test_work_groups?systemPath=${ids.path}&testSystemGroupId=${ids.testSystemGroupId}&testWorkGroupId=${ids.testWorkGroupId}`,
  singleTaskInPlanning: (ids: DetailPageIdTypes[DetailPageKeys.TASK]) =>
    `/actions/table_overview/actions/${ids.taskId}/`,
  taskUnderDelivery: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_UNDER_DELIVERY],
  ) => `/delivery/table_overview/delivery/${ids.deliveryId}/task/${ids.taskId}`,
  taskUnderKeypointDelivery: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_UNDER_KEYPOINT_DELIVERY],
  ) =>
    `/keypoint/table_overview/keypoint/${ids.keypointId}/delivery/${ids.deliveryId}/actions/${ids.taskId}`,
  taskUnderMilestoneKeypointDelivery: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_UNDER_MILESTONE_KEYPOINT_DELIVERY],
  ) =>
    `/milestone/overview/milestone/${ids.milestoneId}/keypoint/${ids.keypointId}/delivery/${ids.deliveryId}/tasks/${ids.taskId}`,
  task: (ids: DetailPageIdTypes[DetailPageKeys.TASK]) => {
    if (
      ids.params['milestoneId'] &&
      ids.params['keypointId'] &&
      ids.params['deliveryId']
    ) {
      return getDetailUrl.taskUnderMilestoneKeypointDelivery({
        taskId: ids.taskId,
        deliveryId: +ids.params['deliveryId'],
        keypointId: +ids.params['keypointId'],
        milestoneId: +ids.params['milestoneId'],
      })
    } else if (ids.params['keypointId'] && ids.params['deliveryId']) {
      return getDetailUrl.taskUnderKeypointDelivery({
        taskId: ids.taskId,
        deliveryId: +ids.params['deliveryId'],
        keypointId: +ids.params['keypointId'],
      })
    } else if (ids.params['deliveryId']) {
      return getDetailUrl.taskUnderDelivery({
        taskId: ids.taskId,
        deliveryId: +ids.params['deliveryId'],
      })
    } else {
      return getDetailUrl.singleTaskInPlanning({
        taskId: ids.taskId,
        params: ids.params,
      })
    }
  },
  taskUnderCaseType: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_UNDER_CASE_TYPE],
  ) => `/tasks/table_overview/tasktypes/${ids.taskTypeId}/tasks/${ids.taskId}`,
  subtask: (ids: DetailPageIdTypes[DetailPageKeys.SUBTASK]) =>
    `/tasks/table_overview/tasktypes/${ids.taskTypeId}/tasks/${ids.parentTaskId}/subtasks/${ids.taskId}`,
  taskWithoutCaseType: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_WITHOUT_CASE_TYPE],
  ) => `/tasks/table_overview/tasktypes/no_task_type/tasks/${ids.taskId}`,

  test: (ids: DetailPageIdTypes[DetailPageKeys.TEST]) =>
    `/systematiccompletion/tests/test-types/${ids.testTypeId}/tests/${ids.testId}`,
  testSystemGroup: (ids: DetailPageIdTypes[DetailPageKeys.TEST_SYSTEM_GROUP]) =>
    `/systems/test_system_groups?testSystemGroupId=${ids.testSystemGroupId}`,
  testSystemGroupSystem: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_SYSTEM_GROUP_SYSTEM],
  ) =>
    `/systems/test_system_groups?testSystemGroupId=${ids.testSystemGroupId}&systemPath=${ids.systemPath}`,
  testType: (ids: DetailPageIdTypes[DetailPageKeys.TEST_TYPE]) =>
    `/systematiccompletion/tests/test-types/${ids.testTypeId}/`,
  testWorkGroup: (ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP]) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}`,
  testWorkGroupTestSystemGroup: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP_TEST_SYSTEM_GROUP],
  ) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}&testSystemGroupId=${ids.testSystemGroupId}`,
  testWorkGroupSystem: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP_SYSTEM],
  ) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}&testSystemGroupId=${ids.testSystemGroupId}&systemPath=${ids.systemPath}`,
  train: (ids: DetailPageIdTypes[DetailPageKeys.TRAIN]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/trains/${ids.trainId}/`,
  trainType: (ids: DetailPageIdTypes[DetailPageKeys.TRAIN_TYPE]) =>
    `/constructions/templates/train_templates/train_types/${ids.trainTypeId}`,
  wagon: (ids: DetailPageIdTypes[DetailPageKeys.WAGON]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/trains/${ids.trainId}/wagons/${ids.wagonId}`,
  wagonType: (ids: DetailPageIdTypes[DetailPageKeys.WAGON_TYPE]) =>
    ids.trainTypeId
      ? `/constructions/templates/train_templates/train_types/${ids.trainTypeId}/locomotive_types/${ids.wagonTypeId}/`
      : `/constructions/templates/wagon_templates/locomotive_types/${ids.wagonTypeId}/`,
}
