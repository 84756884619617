export const systemTypeImports = {
  name: 'system_type_imports',
  displayName: 'system_type_imports',
  columns: ['name', 'type_code', 'description'],
  template: [
    {
      name: 'name',
      required: true,
      value: 'text',
      description: 'name_of_imported_item',
      example: 'T1',
    },
    {
      name: 'type_code',
      required: true,
      value: 'text',
      description: 'type_code',
      example: 'T1',
    },
    {
      name: 'description',
      required: true,
      value: 'text',
      description: 'description_of_imported_item',
      example: 'T1',
    },
  ],
}
