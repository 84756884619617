import Check from '@icons/check.svg'
import Checklist from '@icons/checklist.svg'
import Edit from '@icons/edit.svg'
import OpenInNew from '@icons/open_in_new.svg'
import Undo from '@icons/undo.svg'
import { useQueryClient } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import EditChecklistModal from 'src/components/checklist/checklist/EditChecklistModal'
import { ChecklistStatus } from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import useBoolean from 'src/hooks/useBoolean'
import { useConfirmModal } from 'src/hooks/useConfirmModal'
import { checklistQueryKey } from 'src/query/checklist'
import { updateCheckList } from 'src/service/ChecklistService'
import { IChecklist } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

const ChecklistActionButtons = ({ checklist }: { checklist: IChecklist }) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: [checklistQueryKey] })
  }

  const { toggle, value } = useBoolean(['edit'])

  const updateList = async (updatedList: Partial<IChecklist>) => {
    await updateCheckList(projectId, checklist.id, updatedList)
    reload()
  }
  const executionStarted = useMemo(
    () => checklist?.status !== ChecklistStatus.NOT_STARTED,
    [checklist?.status],
  )
  const executionCompleted = useMemo(
    () => checklist?.status === ChecklistStatus.COMPLETED,
    [checklist?.status],
  )

  const canEditChecklist = useMemo(
    () =>
      (executionStarted || !checklist?.checklist_template) &&
      !executionCompleted,
    [checklist?.checklist_template, executionStarted, executionCompleted],
  )

  const { confirm } = useConfirmModal()
  const onStartExecution = async () => {
    const confirmExecute = await confirm({
      title: `${capitalize(t('start'))} ${t('execution').toLowerCase()}`,
      message: t('confirm_start_checklist_execution'),
      iconColor: 'fill-yellow-status',
    })
    if (!confirmExecute) return
    updateList({
      status: ChecklistStatus.IN_PROGRESS,
    })
  }

  return (
    <div className={'py-2'}>
      <Button
        toolTipText={executionStarted ? t('execution_has_started') : undefined}
        onClick={onStartExecution}
        type={ButtonType.PRIMARY}
        disabled={executionStarted}
      >
        <div className={'flex '}>
          <Checklist className={'text-lg mr-2'} />
          {`${capitalize(t('start'))} ${t('checklist').toLowerCase()}`}
        </div>
      </Button>
      {!executionCompleted && (
        <Button
          toolTipText={
            executionCompleted ? t('execution_is_completed') : undefined
          }
          onClick={() => {
            updateList({
              status: ChecklistStatus.COMPLETED,
            })
          }}
          type={ButtonType.SUCCESS}
          disabled={executionCompleted || !executionStarted}
        >
          <div className={'flex '}>
            <Check
              className={twMerge(
                'text-lg mr-2',
                executionCompleted || !executionStarted
                  ? 'fill-gray-300'
                  : 'fill-white',
              )}
            />
            {`${capitalize(t('complete'))} ${t('checklist').toLowerCase()}`}
          </div>
        </Button>
      )}
      {executionCompleted && (
        <Button
          onClick={() => {
            updateList({
              status: ChecklistStatus.IN_PROGRESS,
            })
          }}
          type={ButtonType.WARNING}
        >
          <div className={'flex '}>
            <Undo className={'text-lg mr-2'} />
            {`${capitalize(t('reopen'))} ${t('checklist').toLowerCase()}`}
          </div>
        </Button>
      )}
      <Button
        toolTipText={
          !canEditChecklist ? t('execution_has_not_started') : undefined
        }
        onClick={() => toggle('new')}
        disabled={!canEditChecklist}
        type={ButtonType.SECONDARY}
      >
        <div className={'flex'}>
          <Edit className={'text-lg mr-2'} />
          {`${capitalize(t('edit'))}`}
        </div>
      </Button>
      {checklist?.checklist_template && (
        <Button isLink type={ButtonType.SECONDARY}>
          <a
            target={'_blank'}
            href={getDetailUrl.checklistTemplate({
              checklistTemplateId: checklist.checklist_template?.id,
              domain: checklist.checklist_template?.domain,
            })}
            type={ButtonType.SECONDARY}
            className={'flex'}
            rel="noreferrer"
          >
            <OpenInNew className={'text-lg mr-2'}></OpenInNew>
            {`${capitalize(t('go_to'))} ${t('checklist_template').toLowerCase()}`}
          </a>
        </Button>
      )}
      {value('new') && (
        <EditChecklistModal
          closeModal={() => {
            reload()
            toggle('new')
          }}
          show={value('new')}
          checklist={checklist}
        />
      )}
    </div>
  )
}

export default ChecklistActionButtons
