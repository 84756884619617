export const deliveryImportTemplate = {
  name: 'delivery_import_template',
  displayName: 'delivery_import_template',
  columns: [
    'record_id',
    'title',
    'deadline',
    'duration_days',
    'discipline',
    'responsible',
    'main_process',
    'team',
    'key_point',
    'description',
    'assumptions',
    'result',
    'status',
    'reporter',
  ],
  template: [
    {
      name: 'record_id',
      required: false,
      value: 'text',
      description: 'record_id',
      example: 'P1-N2-L2',
    },
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_imported_item',
      example: 'Tittel til leveranse',
    },
    {
      name: 'deadline',
      required: true,
      value: 'date_mm_dd_yyyy',
      description: 'deadline_of_imported_item',
      example: '09.11.2020',
    },
    {
      name: 'duration_days',
      required: false,
      value: 'all',
      description: 'duration_of_imported_items',
      example: '2',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_imported_item',
      example: 'CAN-TST',
    },
    {
      name: 'responsible',
      required: true,
      value: 'text',
      description: 'responsible_of_imported_item',
      example: 'Kari Nordmann',
    },
    {
      name: 'main_process',
      required: false,
      value: 'text',
      description: 'mainprocess_of_imported_item',
      example: 'P6',
    },
    {
      name: 'team',
      required: false,
      value: 'text',
      description: 'team_of_imported_item',
      example: 'Kontorer',
    },
    {
      name: 'key_point',
      required: true,
      value: 'text',
      description: 'key_point_of_imported_item',
      example: 'P2-N145',
    },
    {
      name: 'description',
      required: false,
      value: 'text',
      description: 'description_of_imported_item',
      example: 'Beskrivelse til leveransen',
    },
    {
      name: 'assumptions',
      required: false,
      value: 'text',
      description: 'assumptions_of_imported_item',
      example: 'Forutsetninger til leveransen',
    },
    {
      name: 'result',
      required: false,
      value: 'text',
      description: 'effect_of_imported_item',
      example: 'Resultat til leveransen',
    },
    {
      name: 'status',
      required: true,
      value: 'status_types',
      description: 'status_of_imported_items',
      example: 'Utført',
    },
    {
      name: 'reporter',
      required: true,
      value: 'text',
      description: 'reporter_of_imported_item',
      example: 'Ola Nordmann',
    },
  ],
}
