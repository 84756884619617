export const testWorkGroupImport = {
  name: 'test_work_group_import',
  displayName: 'test_work_group',
  columns: [
    'test_work_group_id',
    'title',
    'description',
    'placement',
    'status',
    'priority',
    'discipline',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
  ],
  template: [
    {
      name: 'test_work_group_id',
      required: true,
      value: 'text',
      description: 'test_work_group_id',
      example: 'A1',
    },
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_imported_item',
      example: 'Forskaling',
    },
    {
      name: 'description',
      required: false,
      value: 'text',
      description: 'description_of_imported_item',
      example: 'Beskrivelse',
    },
    {
      name: 'placement',
      required: true,
      value: 'text',
      description: 'placement_import',
      example: 'Taktområde 1',
    },
    {
      name: 'status',
      required: false,
      value: 'status_types',
      description: 'status_of_imported_items',
      example: 'Under arbeid',
    },
    {
      name: 'priority',
      required: false,
      value: 'text',
      description: 'priority_import',
      example: 'priority_example',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_imported_item',
      example: 'CAN-TST',
    },
    {
      name: 'planned_start',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'planned_start_import',
      example: '01.01.2024',
    },
    {
      name: 'planned_end',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'planned_end_import',
      example: '01.01.2024',
    },
    {
      name: 'actual_start',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'actual_start_import',
      example: '01.01.2024',
    },
    {
      name: 'actual_end',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'actual_end_import',
      example: '01.01.2024',
    },
  ],
}
