import { isEqual } from 'lodash'
import { ChangeEvent, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { metadataTimeTypes } from 'src/document/types/FolderMetadataTypes'
import { IChecklistItem } from 'src/service/OrgTypes'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { IStringDropDownItem } from 'src/ui-elements/page-display/inline-components/IDropDown'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'

import MultiSelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'

interface ChecklistValueCell {
  item: IChecklistItem
  onItemUpdate: (updatedItem: Partial<IChecklistItem>) => void
  disabled?: boolean
}

const ChecklistValueCell = ({
  item,
  onItemUpdate,
  disabled,
}: ChecklistValueCell) => {
  const [internalValue, setInternalValue] = useState(
    item.data_value as string | undefined,
  )
  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setInternalValue(val)
  }

  const onValueUpdate = (value?: string | boolean, list?: string[]) => {
    onItemUpdate({
      data_value: value,
      data_value_list: list,
    })
  }

  switch (item.data_type) {
    case 'time':
      return (
        <DateTimeInlineInputComponent
          disabled={disabled}
          includeTime={isEqual(
            item.pattern,
            metadataTimeTypes['dateTime'].pattern,
          )}
          selectedTime={item?.data_value as string | undefined}
          onValueSubmitted={(val) => onValueUpdate(val)}
          cancelButton
        />
      )

    case 'string':
      return (
        <TextareaAutosize
          disabled={disabled}
          tabIndex={0}
          className="w-full bg-transparent whitespace-pre-line p-2"
          onChange={handleChange}
          onBlur={() => {
            onValueUpdate(internalValue)
          }}
          value={internalValue ?? ''}
          onKeyDownCapture={(e) => {
            if (e.key === 'Escape') {
              onValueUpdate(internalValue)
            }
            if (e.key === 'Enter' && e.shiftKey) {
              e.preventDefault()
              onValueUpdate(internalValue)
            }
            e.stopPropagation()
          }}
        />
      )

    case 'enum':
      return (
        <SelectorInlineInputComponent<string>
          disabled={disabled}
          items={(Array.isArray(item.pattern) ? item.pattern : [])
            .sort()
            .map((val) => ({ id: val, name: val }))}
          getItemLabel={(field) => field?.id}
          selectedId={item?.data_value as string | undefined}
          initialItem={{ id: item?.data_value as string | undefined }}
          onValueSubmitted={(val) => onValueUpdate(val)}
          cancelButton
          inspectorPanel
        />
      )

    case 'boolean':
      return (
        <BooleanInlineInputComponent
          label={''}
          labelWidth={'0'}
          disabled={disabled}
          value={item.data_value === 'true'}
          onValueSubmitted={(val) => onValueUpdate(val ? 'true' : 'false')}
          showCheckBox
        />
      )

    case 'multi_enum':
      return (
        <MultiSelectorInlineInputComponent<string, IStringDropDownItem>
          disabled={disabled}
          items={(Array.isArray(item.pattern) ? item.pattern : [])
            .sort()
            .map((val) => ({ id: val, name: val }))}
          getItemLabel={(field) => field.name ?? ''}
          selectedIds={item.data_value_list ?? []}
          initialItems={
            item.data_value_list
              ? item.data_value_list
                  .sort()
                  .map((val) => ({ id: val, name: val }))
              : []
          }
          onValueSubmitted={(list) => onValueUpdate(undefined, list)}
          cancelButton
        />
      )

    default:
      return null
  }
}

export default ChecklistValueCell
