import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CreateChecklistModal from 'src/components/checklist/checklist/CreateChecklistModal'
import { useChecklistColumns } from 'src/components/checklist/checklist/useChecklistColumns'
import useBoolean from 'src/hooks/useBoolean'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import { useFilteredChecklists, useMutateChecklist } from 'src/query/checklist'
import { IChecklist } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface IChecklistTableProps {
  parentId?: number
  parentType?: string
  parentFilter?: TableFilter
  writeAccess?: boolean
  parentDetailUrl?: string
  onRowClick?: (row: IChecklist) => void
}
const ChecklistTable = ({
  parentId,
  parentType,
  parentFilter,
  writeAccess = false,
  onRowClick,
  parentDetailUrl,
}: IChecklistTableProps) => {
  const { toggle, value } = useBoolean(['new'])
  const { deleteList, bulkDeleteLists } = useMutateChecklist({})

  const { t } = useTranslation()
  const { columns, defaultOrdering } = useChecklistColumns({
    disabled: !writeAccess,
    showTemplate: !!parentId,
  })
  const { confirmDelete } = useDeleteModal()

  const onDeleteClick = async (checklist: IChecklist) => {
    const confirm = await confirmDelete({
      itemIdnType: `${checklist.name} (${t('checklist')})`,
      itemName: `${checklist?.name}`,
    })
    if (confirm) {
      deleteList.mutate(checklist.id)
    }
  }

  const initialFilter = useMemo(() => {
    return { parent_type: parentType, parent_id: parentId, ...parentFilter }
  }, [parentType, parentId, parentFilter])

  return (
    <div>
      <Table
        generateRedirectUrl={
          parentDetailUrl
            ? (row) => `${parentDetailUrl}checklistId=${row.id}`
            : undefined
        }
        modelName={DataModel.CHECKLIST}
        initialFilter={initialFilter}
        tableButtons={(selectedItems) => ({
          onBulkDelete: writeAccess
            ? () => bulkDeleteLists.mutate(selectedItems.map((list) => list.id))
            : undefined,
          customButtons:
            writeAccess && parentType && parentId
              ? [
                  <Button
                    key="new"
                    className={'h-8'}
                    type={ButtonType.PRIMARY}
                    onClick={() => toggle('new')}
                  >
                    {t('new_checklist')}
                  </Button>,
                ]
              : [],
        })}
        defaultOrdering={defaultOrdering}
        name={`checklistTable-${parentType ?? 'checklists'}`}
        columns={columns}
        useDataQuery={useFilteredChecklists}
        onDeleteClick={writeAccess ? onDeleteClick : undefined}
        onRowClick={onRowClick}
        selectable={writeAccess}
      />
      {value('new') && parentId && parentType && (
        <CreateChecklistModal
          onCloseModal={() => toggle('new')}
          show={value('new')}
          parentId={parentId}
          parentType={parentType}
        />
      )}
    </div>
  )
}

export default ChecklistTable
